import styled from 'styled-components'


interface SelectContainerProps {
    width?: string;
    padding?: string;
    fontSize?: string;
    theme?: string;
    background?: string;
    border?: string;
    color?: string;
    borderRadius?: string;
    outline?: string;
    textIndent?: string;
    boxShadow?: string;
    cursor?: string;
}

export const StyledSelectContainer = styled.div<SelectContainerProps>`

    & .select__input {
        text-indent: 12px;
        font-family: Roboto;
        
    }

    

    & .select__control {
        text-align: left;
        padding: ${props => props.padding || props.theme.input.padding};
        border: ${props => props.border || 'solid 1px #808080'};
        font-size: ${props => props.fontSize || props.theme.input.fontSize};
        width: ${props => props.width || props.theme.input.width};
        border-radius: ${props => props.borderRadius || props.theme.input.borderRadius};
        margin: auto;
        text-indent: ${props => props.textIndent || '12px'};
        background: ${props => props.background || props.theme.input.background};
        font-family: Roboto;
        ${props => props.boxShadow && `
            box-shadow: ${props.boxShadow};
        `};

        ${props => props.cursor && `
            cursor: ${props.cursor};
        `};
        
    }

    & .select__single-value {
        color: ${props => props.color || '#000'};
        font-weight: 400;
    }

    & .select__control:hover {
        border: ${props => props.border || 'solid 1px #000'};
    }

    & .select__control:focus {
        outline: ${props => props.outline || props.border || '2px solid #2f36b1'}
        border: ${props => props.border || 'solid 1px #000'};
    }
    

    & .select__menu {
        text-align: left;
        font-family: Roboto;
        z-index: 9;
        font-weight: 400;
    }

    & .select__value-container {
        padding: 0;
    }

     & .select__input-container {
        padding: 0;
        margin: 0;
     }

     & .select__indicator {
        color: #000;
     }

     & .select__placeholder {
        color: ${props => props.color || '#000'};
        font-size: ${props => props.fontSize || props.theme.input.fontSize};
        font-family: Roboto;
     }

     @media (min-height: 768px) {
        & .select__control {
            padding: ${props => props.padding || props.theme.input.padding};
        }

        
    }

    @media (min-width: 1024px) {

    }
`


