import { useModalStore, usePostStore, useResponsiveStore } from "../../stores/web.store"
import { Button } from "../Button"
import { Impressions } from "../Button/Impressions"
import { LikeButton } from "../Button/Like"
import { SaveButton } from "../Button/Save"
import { ShareButton } from "../Button/Share"
import { Comments } from "../Comments"
import { Divider } from "../Divider"
import { ImageGallery } from "../ImageGallery"
import { Text } from "../Text"
import { UserInfo } from "../UserInfo"
import { View } from "../View"
import { StyledCommentContainer, StyledDetails, StyledDetailsContainer, StyledPostDetails, StyledPostDetailsContainer, StyledPostPhotoContainer } from "./styled"
import {ReactComponent as SettingsIcon} from '../../img/horizontalSettingsDots.svg'
import {ReactComponent as SendIcon} from '../../img/send.svg'

import { useEffect, useRef, useState } from "react"
import { Markdown } from "../MarkDown"
import { toDate, toDateDiff, useEffectOnce } from "../../utils/app.util"
import { $createParagraphNode, $getRoot } from "lexical"
import api from "../../services/api.service"
import { Follow } from "../Button/Follow"
import { PostCaption } from "../PostCaption"
import { Comment } from "../Button/Comment"
import { useViewed } from "../../utils/analytics.util"
import { DeletePost } from "../Button/DeletePost"
import { LikedBy } from "../LikedBy"
import { AccountActions } from "../Profile/AccountActions"
import { Modal } from "../Modal"


type PostDetialsProps = {
    data?: any;
    isModal?: boolean;
    posts?: any;
    modalDimensions?: any;
    setModalDimensions?: any;
}


const AccountActionsModal = ({setIsModal}: any) => {
    return (
        <Modal
            setModal={setIsModal} 
            width={'100%'} 
            height={'100vh'}
            background="rgba(0, 0, 0, .5)"
            isOverlay
        >
            <AccountActions setIsModal={setIsModal} />
        </Modal>
    )
}


const PostDetails = ({data, isModal, posts, modalDimensions, setModalDimensions}: PostDetialsProps) => {
    const [comment, setComment] = useState<any>({})
    const post = usePostStore((state: any) => state.post)
    const setPost = usePostStore((state: any) => state.setPost)
    const modalKey = useModalStore((state: any) => state.modalKey)
    const width = useResponsiveStore((state: any) => state.width)
    const setModalKey = useModalStore((state: any) => state.setModalKey)

    const [isSettingsModal, setIsSettingsModal] = useState(false)
    
    
    const editorStateRef = useRef<any>();

    useViewed({
        source: 'post',
        source_id: data?.id
    });

    const onChangeEditorState = (editorState: any) => {
        
        editorStateRef.current = editorState
        const msg = editorState.read(() => $getRoot().getTextContent())
        setComment({
            comment: msg,
            lexical_comment: JSON.stringify(editorStateRef.current)

        })

    }
    
    const onClickSend = async () => {
        const resp = await api.post('/comments', {
            ...comment,
            source: post.type,
            source_id: post.id

        })
        setPost({
            ...post,
            comments: [
                ...post.comments,
                resp.data
            ]
        })

        if (editorStateRef.current) {
            editorStateRef.current.update(() => {
                const root = $getRoot();
                root.clear();
                root.append($createParagraphNode());
            });
        }
        
    }

    const handleEnterCommand = async (event: any, editor: any) => {
        event.preventDefault();
        // const serializedContent = $generateHtmlFromNodes(editor, null);

        const resp = await api.post('/comments', {
            ...comment,
            source: post.type,
            source_id: post.id
        })
        
        setPost({
            ...post,
            comments: [
                ...post.comments,
                resp.data
            ]
        })
        return true
    };

    const onClickSettings = () => {
        setIsSettingsModal(true)
    }
    

    // if (!post.comments || !post.owner.followers) return <div />

    console.log((comment.comment || '').length)

    return (
        <StyledPostDetailsContainer>
            {/* <BackNavigator posts={posts} data={post}/>
            <NextNavigator posts={posts} data={post}/> */}
            <StyledPostDetails isPost={modalKey === 'post'} modalDimension={modalDimensions}>
                {width <= 768 && <View padding="48px 8px 12px 8px">
                    <View display="inline-flex" alignItems="center" width="100%" gap="24px">
                        <View width="70%">
                            <UserInfo account={post.owner}/>
                        </View>
                        <View textAlign="right" width="30%">
                            <Follow data={post.owner} noStyle/>
                        </View>
                    </View>
                </View>}
                <StyledPostPhotoContainer>
                    <ImageGallery 
                        modalDimensions={modalDimensions} 
                        setModalDimensions={setModalDimensions} 
                        data={post} height={modalKey === 'post' ? '90vh' : "auto"} 
                        isModal={isModal} 
                        objectFit={modalKey === 'post' ? modalDimensions.objectFit : 'contain'} 
                    />
                </StyledPostPhotoContainer>
                {modalKey === 'post' && <StyledDetailsContainer>
                    <StyledDetails>
                        <View padding="12px 24px 0px 12px">
                            {width > 768 && <View padding="0px 0px 4px 0px">
                                <View display="inline-flex" alignItems="center" width="100%" gap="0px">
                                    <View width="70%">
                                        <View display="inline-flex" alignItems="center" gap="8px" width="100%">
                                            <UserInfo account={post.owner}/>
                                            <Text>•</Text>
                                            <Follow data={post.owner} noStyle activeColor="#0938F0"/>
                                        </View>
                                    </View>
                                    <View textAlign="right" width="30%">
                                        <Button noStyle onClick={onClickSettings}>
                                            <SettingsIcon height={20} width={20}/>
                                        </Button>
                                        {/* <DeletePost post={post}/> */}
                                    </View>
                                </View>
                            </View>}
                            {/* <View>
                                <PostCaption data={post}/>
                            </View> */}
                            
                            {/* {post.comment && <View padding="12px 0px 0px 0px">
                                <Text>{post.comment}</Text>
                            </View>} */}
                            
                            
                        </View>
                        <Divider />
                        <View padding="0px 12px">
                            {/* <View padding="12px 0px 0px 0px">
                                <Text fontWeight="500">Comments ({post.comments?.length})</Text>
                            </View> */}
                            <Comments post={post}/>
                        </View>
                        
                        <StyledCommentContainer>
                            <Divider />
                            <View padding="0px 12px">
                                <View padding="4px 0px">
                                    <View display="inline-flex" alignItems="center" width="100%">
                                        <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                                            <LikeButton data={post}/>
                                            <Impressions data={post} />
                                        </View>
                                        <View display="inline-flex" alignItems="center" width="100%" justifyContent="right" gap="12px">
                                            <SaveButton data={post} noTitle/>
                                            <ShareButton data={post} noTitle/>
                                        </View>
                                    </View>
                                </View>
                                <View padding="4px 0px 20px 0px">
                                    <LikedBy postData={post} />
                                    <View>
                                        <Text color="grey">{toDateDiff(post.created_date, true, 'd')}</Text>
                                    </View>
                                </View>
                            </View>
                            <View position="relative" borderTop="solid 1px #e0e0e0" padding="0px 12px">
                                {!post.privacy_settings.is_comments_disabled ? <View display="inline-flex" alignItems="center" width="100%">
                                    <View flexGrow="1">
                                        <Markdown 
                                            editorStateRef={editorStateRef} 
                                            data={null} 
                                            singleLine 
                                            onClickSend={onClickSend}
                                            onChange={onChangeEditorState} 
                                            placeholder={'Add a comment'} 
                                            handleEnterCommand={handleEnterCommand}
                                            // emoji={{padding: '0px 0px 0px 8px'}}
                                            editable
                                            noTools
                                        />
                                    </View>
                                    <View position="absolute" right="20px" top="16px">
                                        <Button noStyle onClick={onClickSend} disabled={(comment.comment || '').length === 0}>
                                            {/* <SendIcon height={20} width={20}/> */}
                                            <Text>post</Text>
                                        </Button>
                                    </View>
                                    </View> : <View>
                                    <Text padding="0px 0px 24px 0px">Commenting Disabled</Text>    
                                </View>}
                            </View>
                        </StyledCommentContainer> 
                    </StyledDetails>
                </StyledDetailsContainer>}
            </StyledPostDetails>
            {isSettingsModal && <AccountActionsModal setIsModal={setIsSettingsModal}/>}
        </StyledPostDetailsContainer>
    )
}

export default PostDetails