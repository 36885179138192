import styled from "styled-components";

interface LastNameProps {
}

export const StyledLastNameContainer = styled.div<LastNameProps>`
    width: 100%;
    position: relative;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
`
