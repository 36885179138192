import styled from "styled-components";

interface UrlProps {
}

export const StyledUrlContainer = styled.div<UrlProps>`
    width: 100%;
    position: relative;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
`
