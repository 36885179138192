import { useEffect, useState } from "react"
import { useResponsiveStore } from "../../stores/web.store"
import { StyledTextAreaContainer, StyledTextArea, StyledMessage } from "./styled"
import { Desktop, Laptop } from "../../utils/responsive.util"

interface TextAreaProps {
    payload: any
    setPayload: (payload: any) => void
    textKey: string
    placeholder?: string
    required?: boolean
    validationMessage?: string
    minLength?: number
    maxLength?: number
}

export const TextArea = ({
    payload, 
    setPayload, 
    textKey,
    placeholder,
    required = false,
    validationMessage = 'This field is required',
    minLength,
    maxLength
}: TextAreaProps) => {
    const mode = useResponsiveStore((state: any) => state.mode)

    const [message, setMessage] = useState<any>(null)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    useEffect(() => {
        if (message && !payload[textKey]) {
            setMessage(null)
        }
    }, [isInvalid, textKey, payload])

    const onChange = (e: any) => {
        const value = e.target.value
        if (maxLength && value.length > maxLength) return
        setPayload({...payload, [textKey]: value})
    }

    const onBlur = () => {
        if (required && !payload[textKey]) {
            setMessage(validationMessage)
            setIsInvalid(true)
            return
        }
        if (minLength && payload[textKey].length < minLength) {
            setMessage(`Minimum ${minLength} characters required`)
            setIsInvalid(true)
            return
        }
        setIsInvalid(false)
    }

    return (
        <StyledTextAreaContainer>
            <StyledTextArea
                placeholder={placeholder}
                value={payload[textKey] || ''}
                onChange={onChange}
                onBlur={onBlur}
                invalid={isInvalid}
                padding={[Desktop, Laptop].includes(mode) ? '12px' : '16px'}
                fontSize="16px"
                background="unset"
                border="solid 1px #787878"
                borderRadius="8px"
                color="#787878"
            />
            {message && <StyledMessage>{message}</StyledMessage>}
        </StyledTextAreaContainer>
    )
}

export default TextArea
