import React, { useState, useRef, useEffect, useCallback } from 'react';
import { View } from '../../View';
import { Text } from '../../Text';
import { Button } from '../../Button';
import { Modal } from '../../Modal';
import styled from 'styled-components';

interface ImageState {
    scale: number;
    position: { x: number; y: number };
    rotation: number;
}

interface StyledImageProps extends ImageState {
    src: string;
}

interface MediaEditorProps {
    files: File[];
    onSave: (files: { file: File; preview: string; state: ImageState }[]) => void;
    onClose: () => void;
    isOpen: boolean;
    initialState?: ImageState;
}

interface ImageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    isDragging?: boolean;
}

const StyledEditorContainer = styled(View)`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const StyledImageContainer = styled(View)<ImageContainerProps>`
    position: relative;
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: ${props => props.isDragging ? 'grabbing' : 'grab'};
    background: #f5f5f5;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: ${props => props.isDragging ? `
            linear-gradient(90deg, transparent 49.5%, rgba(128, 128, 128, 0.15) 49.5%, rgba(128, 128, 128, 0.15) 50.5%, transparent 50.5%),
            linear-gradient(0deg, transparent 49.5%, rgba(128, 128, 128, 0.15) 49.5%, rgba(128, 128, 128, 0.15) 50.5%, transparent 50.5%)
        ` : 'none'};
        background-size: 50px 50px;
        pointer-events: none;
        transition: opacity 0.2s ease;
        opacity: ${props => props.isDragging ? 1 : 0};
        will-change: opacity;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: ${props => props.isDragging ? `
            linear-gradient(90deg, transparent 33%, rgba(128, 128, 128, 0.25) 33%, rgba(128, 128, 128, 0.25) 34%, transparent 34%, transparent 66%, rgba(128, 128, 128, 0.25) 66%, rgba(128, 128, 128, 0.25) 67%, transparent 67%),
            linear-gradient(0deg, transparent 33%, rgba(128, 128, 128, 0.25) 33%, rgba(128, 128, 128, 0.25) 34%, transparent 34%, transparent 66%, rgba(128, 128, 128, 0.25) 66%, rgba(128, 128, 128, 0.25) 67%, transparent 67%)
        ` : 'none'};
        pointer-events: none;
        transition: opacity 0.2s ease;
        opacity: ${props => props.isDragging ? 1 : 0};
        will-change: opacity;
    }
`;

const StyledImage = styled.img<StyledImageProps>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: ${props => `
        translate(${props.position?.x || 0}px, ${props.position?.y || 0}px)
        scale(${props.scale || 1})
        rotate(${props.rotation || 0}deg)
    `};
    transition: transform 0.1s ease-out;
    max-width: none;
`;

const Controls = styled(View)`
    padding: 16px;
    border-top: 1px solid #eee;
`;

const MediaEditor: React.FC<MediaEditorProps> = ({ files, onSave, onClose, isOpen, initialState }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [scale, setScale] = useState(initialState?.scale || 1);
    const [position, setPosition] = useState(initialState?.position || { x: 0, y: 0 });
    const [rotation, setRotation] = useState(initialState?.rotation || 0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);
    const [editedFiles, setEditedFiles] = useState<{ file: File; preview: string; state: ImageState }[]>([]);
    
    const imageRef = useRef<HTMLImageElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!initialState) {
            setScale(1);
            setPosition({ x: 0, y: 0 });
            setRotation(0);
        }
    }, [initialState, currentIndex]);

    const handleDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!containerRef.current) return;
        
        setIsDragging(true);
        setDragStart({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleDrag = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !dragStart) return;

        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;

        setPosition({
            x: newX,
            y: newY
        });
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        setDragStart(null);
    };

    const handleZoom = (delta: number) => {
        setScale(Math.max(1, Math.min(3, scale + delta)));
    };

    const handleRotate = () => {
        setRotation((rotation + 90) % 360);
    };

    const captureEditedImage = useCallback(async () => {
        if (!imageRef.current || !containerRef.current) return null;

        // Create a canvas matching the container's visible dimensions
        const previewCanvas = document.createElement('canvas');
        const previewCtx = previewCanvas.getContext('2d');
        if (!previewCtx) return null;

        const containerRect = containerRef.current.getBoundingClientRect();
        previewCanvas.width = containerRect.width;
        previewCanvas.height = containerRect.height;

        // Draw the preview exactly as shown in the modal
        previewCtx.save();
        previewCtx.fillStyle = '#f5f5f5';
        previewCtx.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
        
        previewCtx.translate(previewCanvas.width / 2, previewCanvas.height / 2);
        previewCtx.rotate((rotation * Math.PI) / 180);
        previewCtx.scale(scale, scale);
        previewCtx.translate(-previewCanvas.width / 2 + position.x, -previewCanvas.height / 2 + position.y);

        previewCtx.drawImage(
            imageRef.current,
            0, 0,
            previewCanvas.width,
            previewCanvas.height
        );
        previewCtx.restore();

        // Use original file with preview URL
        const previewUrl = previewCanvas.toDataURL('image/jpeg', 1.0);
        return {
            file: files[currentIndex],
            preview: previewUrl,
            state: {
                scale,
                position,
                rotation,
            }
        };
    }, [currentIndex, files, position, rotation, scale]);

    const handleNext = async () => {
        const result = await captureEditedImage();
        console.log('Captured image result:', result); // Debug log
        
        if (result) {
            const updatedFiles = [...editedFiles, result];
            console.log('Updated files:', updatedFiles); // Debug log
            
            if (currentIndex < files.length - 1) {
                setEditedFiles(updatedFiles);
                setCurrentIndex(prev => prev + 1);
                setScale(1);
                setPosition({ x: 0, y: 0 });
                setRotation(0);
            } else {
                console.log('Saving files:', updatedFiles); // Debug log
                onSave(updatedFiles);
                setEditedFiles([]);
                setCurrentIndex(0);
            }
        }
    };

    return (
        <Modal setModal={onClose} width="800px" height="fit-content">
            <StyledEditorContainer>
                <View padding="16px" display="flex" justifyContent="space-between" alignItems="center">
                    <Button noStyle onClick={onClose}>Cancel</Button>
                    <Text>Edit Photo {currentIndex + 1} of {files.length}</Text>
                    <Button noStyle onClick={handleNext}>
                        {currentIndex === files.length - 1 ? 'Done' : 'Next'}
                    </Button>
                </View>

                <StyledImageContainer 
                    ref={containerRef}
                    isDragging={isDragging}
                    onMouseDown={handleDragStart}
                    onMouseMove={handleDrag}
                    onMouseUp={handleDragEnd}
                    onMouseLeave={handleDragEnd}
                >
                    {files[currentIndex] && (
                        <StyledImage
                            ref={imageRef}
                            src={URL.createObjectURL(files[currentIndex])}
                            scale={scale}
                            position={position}
                            rotation={rotation}
                            alt=""
                            draggable={false}
                        />
                    )}
                </StyledImageContainer>

                <Controls padding="16px" display="flex" justifyContent="center" gap="16px">
                    <Button noStyle onClick={() => handleZoom(-0.1)}>-</Button>
                    <Button noStyle onClick={() => handleZoom(0.1)}>+</Button>
                    <Button noStyle onClick={handleRotate}>↻</Button>
                </Controls>
            </StyledEditorContainer>
        </Modal>
    );
};

export default MediaEditor;
