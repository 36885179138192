import { useEffect, useRef, useState } from "react";
import { View } from "../../View";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { StyledPriceRange } from "./styled";
import { useFiltersStore } from "../../../stores/web.store";

const useOutsideAlerter = (ref: any, setIsExpanded: any) => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setIsExpanded(false);
            }
        }

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);
        
        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setIsExpanded]);
}

const PriceRange = () => {
    const [value, setValue] = useState([0, 100000]);
    const [isExpanded, setIsExpanded] = useState(false)
    const wrapperRef = useRef(null);

    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    
    const updateFilterStore = async (value: any) => {
        const filterKey = 'metadata.price'
        setFilterStore({...filterStore, loaded: true, filters: {
            ...filterStore.filters,
            [filterKey]: {condition: "range", value}
        }})
    }

    const onSlide = async (e: any) => {
        setValue(e)
        updateFilterStore(e)
    }

    const onChangeMin = (e: any) => {
        setValue([parseInt(e.target.value), value[1]])
        updateFilterStore([parseInt(e.target.value), value[1]])
    }

    const onChangeMax = (e: any) => {
        setValue([value[0], parseInt(e.target.value)])
        updateFilterStore([value[0], parseInt(e.target.value)])
    }

    const onClick = () => {
        setIsExpanded(!isExpanded)
    }

    const onClickClear = () => {
        setValue([0, 100000])
        updateFilterStore([0, 100000])
    }

    useOutsideAlerter(wrapperRef, setIsExpanded);

    return (
        <View position="relative" ref={wrapperRef}>
            <View>
                <Button noStyle padding="12px 24px" border="solid 1px #808080" borderRadius="24px" onClick={onClick} fontWeight="500">{`Price Range`}</Button>
            </View>
            {isExpanded && (
                <View position="absolute" top="48px" zIndex="4" border="solid 1px #808080" borderRadius="4px" background="#fff">
                    <View padding="24px 12px 12px 12px" width="260px">
                        <StyledPriceRange>
                            <RangeSlider min={0} max={100000} step={100} value={value} onInput={onSlide} />
                        </StyledPriceRange>
                        <View padding="12px 0px">
                            <View display="inline-flex" alignItems="center" width="calc(100% - 4px)" gap="24px">
                                <View width="50%" padding="0px 0px 0px 4px">
                                    <Input type="number" value={value[0]} onChange={onChangeMin} width="100%" placeholder="Min"/>
                                </View>
                                <View width="50%">
                                    <Input type="number" value={value[1]} onChange={onChangeMax} width="100%" placeholder="Max"/>
                                </View>
                            </View>
                        </View>
                        <View padding="12px 0px">
                            <View display="inline-flex" alignItems="center" width="100%" gap="12px">
                                <View width="50%">
                                    <Button 
                                        width="100%" 
                                        noStyle 
                                        border="solid 1px #808080" 
                                        padding="8px 16px" 
                                        borderRadius="16px"
                                        onClick={onClickClear}
                                    >{`Clear`}</Button>
                                </View>
                                <View width="50%">
                                    <Button 
                                        width="100%" 
                                        border="solid 1px #808080" 
                                        padding="8px 16px" 
                                        borderRadius="16px"
                                        color="#fff"
                                        onClick={onClick}
                                    >{`Confirm`}</Button>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            )}
        </View>
    )
}

export default PriceRange