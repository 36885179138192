import { useEffect } from "react"
import { useAccountStore, usePostsStore } from "../../stores/web.store"
import { FeedPost } from "../FeedPost"
import { StyledFeed, StyledFeedContainer } from "./styled"
import { View } from "../View"
import { useThemeStore } from "../../stores/theme.store"


const Feed = ({posts}: any) => {
    const theme = useThemeStore((state: any) => state.theme)
    const account = useAccountStore((state: any) => state.account)
    const setPosts = usePostsStore((state: any) => state.setPosts)
    useEffect(() => {
        setPosts(posts)
    }, [])

    return (
        <StyledFeedContainer id="FeedContainer" theme={theme}>
            <StyledFeed>
                {posts.filter((post: any) => account.id !== post.user_id).map((obj: any) => {
                    return (
                        <View key={`feed-post-${obj.id}`}>
                            <FeedPost data={obj} type={obj.state}/>
                        </View>
                    )
                })}
            </StyledFeed>
        </StyledFeedContainer>
    )
}

export default Feed