import { Button } from "../../../components/Button"
import { Text } from "../../../components/Text"
import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { TrendingNow } from "../../../sections/TrendingNow"
import { StyledCard, StyledCards, StyledHeroContainer } from "./styled"
import {ReactComponent as PinIcon} from '../../../img/pin.svg'
import {ReactComponent as BuildingIcon} from '../../../img/building.svg'
import { useResponsiveStore } from "../../../stores/web.store"



type CardProps = {
    title: string;
    location: any;
    type: string;
    onClick: any;
}


const Card = ({title, location, type, onClick}: CardProps) => {
    return (
        <StyledCard>
            <View display="flex" flexDirection="column" gap="12px">
                <View>
                    <Text fontSize="16px" fontWeight="500">{title}</Text>
                </View>
                <View>
                    <View display="inline-flex" gap="24px" alignItems="center">
                        <View>
                            <View display="inline-flex" gap="6px" alignItems="center">
                                <PinIcon />
                                <Text>{`${location.city}, ${location.state}`}</Text>
                            </View>
                        </View>
                        <View>
                            <View display="inline-flex" gap="6px" alignItems="center">
                                <BuildingIcon />
                                <Text>{type}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View>
                    <Button
                        border="solid 1px #000"
                        background="#fff"
                        padding="6px 20px"
                        fontSize="13px"
                        borderRadius="24px"
                        onClick={onClick}
                    >Apply</Button>
                </View>
            </View>
        </StyledCard>
    )
}





const ContactUs = () => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <DashboardLayout>
            
            <View padding={width > 768 ? "48px" : "12px"} display="flex" flexDirection="column" gap="48px">
                <View>
                    <View display="flex" flexDirection="column" gap="40px">
                        <View>
                            <Text width="100%" fontSize={width > 768 ? "36px" : "28px"} fontWeight="500">{"Contact Us"}</Text>
                        </View>
                        <View>
                            <Text width="100%" fontSize={width > 768 ? "32px" : "24px"} fontWeight="500">{"General"}</Text>
                        </View>
                        <View>
                            <Text width={width > 768 ? "70%" : "100%"} fontSize={width > 768 ? "32px" : "24px"} color="#979797">For general questions and feedback, please contact <a style={{textDecoration: 'none'}} href="mailto:hello@primaryyellow.com">hello@primaryyellow.com</a></Text>
                        </View>
                    </View>
                </View>
                <View>
                    <View display="flex" flexDirection="column" gap="20px">
                        <View>
                            <Text width="100%" fontSize={width > 768 ? "32px" : "24px"} fontWeight="500">{"Address"}</Text>
                            <Text textAlign="center" width="100%" fontSize="16px">{'Tribeca, New York, NY 10013'}</Text>
                        </View>
                        <StyledHeroContainer />
                    </View>
                </View>
                <View>
                    <FooterAd image={'/img/landing/about/LaunchingSoon.svg'} />
                </View>
            </View>
           
            
        </DashboardLayout>
    )
}

export default ContactUs