import { useState } from "react"
import { Plaid } from "../../components/Services/Plaid"
import { DiscoverPeople } from "../../sections/DiscoverPeople"
import { Feed } from "../../sections/Feed"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { RecentActivity } from "../../sections/RecentActivity"
import { StyledDashboard, StyledDashboardContainer, StyledDashboardContent, StyledInfoSection } from "./styled"
import { Onboarding } from "../../components/Services/Stripe/Onboarding"
import { FeedNavigation } from "../../components/FeedNavigation"
import { View } from "../../components/View"
import { useThemeStore } from "../../stores/theme.store"
import { SearchBar } from "../../components/SearchBar"

const SearchSection = () => {
    const theme = useThemeStore((state: any) => state.theme)
    return (
        <View>
            <View 
                padding="12px 24px" 
                background={theme.CardPrimaryBackgroundColor} 
                display="inline-flex"
                alignItems="center"
                gap="12px"
                width="100%"
                borderBottom={`solid 1px ${theme.BorderColor}`}
            >
                <View width="80%">
                    <SearchBar width="400px" placeholder="Type a command to search..." border="none" background={theme.SearchBarBackgroundColor}/>
                </View>
                <View width="20%">

                </View>
            </View>
        </View>
    )
}

const Dashboard = () => {
    return (
        <DashboardLayout>
            <StyledDashboardContainer>
                <StyledDashboard>
                    <SearchSection />
                    <FeedNavigation />
                    {/* <Plaid /> */}
                    {/* <Onboarding /> */}
                    <StyledDashboardContent>
                        <Feed />
                        <StyledInfoSection>
                            <RecentActivity />
                            <DiscoverPeople />
                        </StyledInfoSection>
                    </StyledDashboardContent>
                </StyledDashboard>
            </StyledDashboardContainer>
        </DashboardLayout>
    )
}

export default Dashboard