import { StyledSave, StyledSaveContainer } from "./styled"
import {ReactComponent as SaveIcon} from '../../../img/save.svg'
import { Text } from "../../Text";
import Button from "../Button";
import api from "../../../services/api.service";
import { useState } from "react";

type SaveProps = {
    data?: any;
    noTitle?: boolean;
    height?: number;
    width?: number;
}


const Save = ({data, noTitle, height, width}: SaveProps) => {
    const [postData, setPostData] = useState(data)
    const [isSaved, setIsSaved] = useState(postData.saved?.status === 'active')
    const onClick = async () => {
        setIsSaved(!isSaved)
        if (postData.saved?.id && !!isSaved) {
            api.delete(`/saved/${postData.saved?.id}`)
        } else {
            const resp = await api.post('/saved', {
                source: postData.type,
                source_id: postData.id
            })

            setPostData({...postData, saved: resp.data})
        }
        
    }

    return (
        <StyledSaveContainer>
            <StyledSave>
                <Button onClick={onClick} noStyle>
                    <SaveIcon 
                        fill={isSaved ? '#FFDC39' : '#FFF'} 
                        stroke={isSaved ? '#FFDC39' : '#000'}
                        height={height || 20}
                        width={width || 20}
                    />
                    {!noTitle && <Text>{`Save`}</Text>}
                </Button>
            </StyledSave>
        </StyledSaveContainer>
    )
}

export default Save