import { Text } from "../Text"
import { ProfilePicture } from "../ProfilePicture"
import { StyledDiscoverPeoplePostContainer } from "./styled"
import { View } from "../View"
import { Follow } from "../Button/Follow"


const DiscoverPeoplePost = ({data}: any) => {
    return (
        <StyledDiscoverPeoplePostContainer>
            <View width="100%" display="inline-flex" alignItems="center" gap="4px">
                <View width="80%">
                    <ProfilePicture 
                        height="32px" 
                        width="32px" 
                        textAlign="left" 
                        account={data} 
                        photo 
                        username 
                        role
                    />
                </View>
                <View width="20%" textAlign="right">
                    <Follow data={data}/>
                </View>
            </View>
            <View width="100%" position="absolute" bottom="-16px">
                <View display="inline-flex" width="100%" gap="0px">
                    <View width="42px"></View>
                    {data.followers.length > 0 ? <Text fontSize="11px">Followed by <ProfilePicture account={data} username fontSize="11px"/> {data.total_followers > 1 ? `and ${data.total_followers - 1} other${data.total_followers - 1 > 1 ? 's' : ''}` : ''}</Text> : <Text fontSize="11px" color="#808080">Primary Yellow Recommended</Text>}
                </View>
            </View>
            
        </StyledDiscoverPeoplePostContainer>
    )
}

export default DiscoverPeoplePost