import styled from "styled-components";

interface NotificationsContainerProps {

}

export const StyledNotificationsContainer = styled.div<NotificationsContainerProps>`

`

interface NotificationsProps {

}

export const StyledNotifications = styled.div<NotificationsProps>`
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
`

interface NotificatiosWrapperContainerProps {

}

export const StyledNotificationsWrapper = styled.div<NotificatiosWrapperContainerProps>`
    width: 100%;
    display: flex; 
    flex-direction: column; 
    gap: 4px;
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 112px);

    @media (min-width: 768px) {
        height: 90vh;
    }

    @media (min-width: 1024px) {

    }
    
`

interface NotificationContainerProps {

}

export const StyledNotificationContainer = styled.div<NotificationContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    
`

interface NotificationProps {

}

export const StyledNotification = styled.div<NotificationProps>`
    width: 100%;
    &:hover {
        background-color: #f1f1f1;
    }
`
