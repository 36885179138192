import styled from "styled-components";

interface TextAreaProps {
    invalid?: boolean;
    background?: string;
    border?: string;
    borderRadius?: string;
    color?: string;
    fontSize?: string;
    padding?: string;
}

export const StyledTextAreaContainer = styled.div<TextAreaProps>`
    width: 100%;
    position: relative;
`

export const StyledTextArea = styled.textarea<TextAreaProps>`
    width: calc(100% - 24px);
    min-height: 120px;
    resize: vertical;
    background: ${props => props.background || 'unset'};
    border: ${props => props.invalid ? 'solid 1px #CB3211' : props.border || 'solid 1px #787878'};
    border-radius: ${props => props.borderRadius || '8px'};
    color: ${props => props.color || '#787878'};
    font-size: ${props => props.fontSize || '16px'};
    padding: ${props => props.padding || '12px'};
    outline: none;
    font-family: inherit;

    &::placeholder {
        color: #787878;
    }

    &:focus {
        border-color: #000000;
    }
`

export const StyledMessage = styled.div`
    position: absolute;
    bottom: -20px;
    left: 0;
    color: #CB3211;
    font-size: 12px;
`
