import { useEffect, useState } from "react"
import { useResponsiveStore } from "../../../stores/web.store"
import { StyledFirstNameContainer } from "./styled"
import Input from "../Input"
import { Desktop, Laptop } from "../../../utils/responsive.util"

export const FirstName = ({payload, setPayload}: any) => {
    const mode = useResponsiveStore((state: any) => state.mode)

    const [validationMessage, setValidationMessage] = useState<any>(null)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    useEffect(() => {
        if (validationMessage && !payload.firstName) {
            setValidationMessage(null)
        }
    }, [isInvalid])

    const onChange = (e: any) => {
        setPayload({...payload, firstName: e.target.value})
    }

    const onBlur = () => {
        if (!payload.firstName) {
            setValidationMessage('First name is required')
            setIsInvalid(true)
            return
        }
        setIsInvalid(false)
    }

    return (
        <StyledFirstNameContainer>
            <Input
                placeholder="First name"
                value={payload.firstName}
                onChange={onChange}
                onBlur={onBlur}
                padding={[Desktop, Laptop].includes(mode) ? '12px 0px' : '16px 0px'}
                fontSize={'16px'}
                width={'100%'}
                message={validationMessage}
                setMessage={setValidationMessage}
                invalid={isInvalid}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
        </StyledFirstNameContainer>
    )
}

export default FirstName