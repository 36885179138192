import { create } from 'zustand'


const _setTheme = (data: any) => {
  localStorage.setItem('theme', data)
  
  
  if (data === 'dark') {
    return {
      mode: 'dark',
      BackgroundColorTheme: '#1E1E1E',
      HoverPrimaryBackgroundColor: '#EFEFEF',
      CardPrimaryBackgroundColor: '#252526',
      BackgroundColorPrimary: '#252526',
      TextColorPrimary: '#FFFFFF',
      TextColorPrimaryLighter: '#BBBBBB',
      FontFamilyPrimary: `"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif`,
      PlaceholderColor: '#64748B',
      PrimaryYellow: '#FAE04C',
      SearchBarBackgroundColor: '#F8FAFC',
      BorderColor: '#3A3A3A',
    }
  }
  return {
    mode: 'light',
    BackgroundColorTheme: '#FCFBF8',
    BackgroundColorPrimary: '#FFFFFF',
    HoverPrimaryBackgroundColor: '#EFEFEF',
    CardPrimaryBackgroundColor: '#FFFFFF',
    TextColorPrimary: '#000',
    TextColorPrimaryLighter: '#64748B',
    FontFamilyPrimary: `"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif`,
    PlaceholderColor: '#64748B',
    PrimaryYellow: '#FAE04C',
    SearchBarBackgroundColor: '#F8FAFC',
    BorderColor: 'rgba(229, 231, 235, 1)',
  }
}

export const useThemeStore = create((set) => ({
  theme: _setTheme(localStorage.getItem('theme')),
  setTheme: (data: any) => set((state: any) => ({theme: (_setTheme(data))})),
}))