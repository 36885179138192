import { usePostStore, useProductDetailPageStore } from "../../stores/web.store"
import { SlideMenu } from "../SlideMenu"
import View from "../View/View"
import { StyledBackNavigator, StyledNextNavigator, StyledViewingRoom, StyledViewingRoomContainer } from "./styled"
import { ReactComponent as XIcon } from '../../img/x.svg'
import {ReactComponent as LeftIcon} from '../../img/leftNavigator.svg'
import {ReactComponent as RightIcon} from '../../img/rightNavigator.svg'
import {ReactComponent as ArtworkIcon} from '../../img/artwork.svg'
import { CURRENCY_DISPLAY_MAP } from "../../map.app"
import { Purchase } from "../Button/Purchase"
import { ProfilePicture } from "../ProfilePicture"
import { GalleryTemplates } from "../GalleryTemplates"
import { Button } from "../Button"
import { useEffect, useState } from "react"
import { key2Value, toCurrency } from "../../utils/app.util"
import { Text } from "../Text"
import { Offer } from "../Button/Offer"
import { Divider } from "../Divider"
import { ShippingAndFees } from "../../sections/ShippingAndFees"
import { GalleryInfo } from "../GalleryInfo"
import { AboutTheWork } from "../../sections/AboutTheWork"
import { OfferModal } from "../Modal/OfferModal"

const PurchaseShowArtwork = ({post}: any) => {
    const [isOfferModal, setIsOfferModal] = useState(false)

    if (!post) return <div />

    return (
        <View height="calc(100vh - 48px)" overflowY="auto">
            <View padding="24px 0px 0px 0px">
                <ProfilePicture account={post.owner} username fontSize="24px"/>
            </View>
            <View>
                <View color="grey" padding="12px 0px">
                    <Text fontSize="24px" fontWeight="500">{post.title}, {post.date || '2024'}</Text>
                    <View padding="12px 0px" display="flex" flexDirection="column" gap="4px">
                        <View display="inline-flex" alignItems="center">
                            <Text textTransform="capitalize">{post.metadata.medium}</Text>
                            <Text></Text>
                        </View>
                        <Text>{post.metadata.dimensions || '00 x 00 x 00'}</Text>
                        <View display="inline-flex" alignItems="center" gap="4px">
                            <ArtworkIcon stroke="grey" fill="grey" height={15} width={15}/>
                            <Text textTransform="capitalize">{key2Value(post.metadata.rarity)}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text fontWeight="500" fontSize="24px">{toCurrency(post.state === 'auction' ? post.metadata.starting_bid : post.metadata.price, post.metadata.currency)}</Text>
                </View>
            </View>
            <View padding="12px 0px">
                <div style={{padding: '12px 0px 0px 0px'}}>
                    <Purchase />
                </div>
                <div style={{padding: '12px 0px'}}>
                    <Offer isModal={isOfferModal} setIsModal={setIsOfferModal}/>
                </div>
            </View>
            <Divider />
            <View>
                <View>
                    <ShippingAndFees />
                </View>
                <Divider />
                <View>
                    <GalleryInfo data={post.owner} />
                </View>
                <Divider />
                <View padding="12px 0px">
                    <Text 
                        fontWeight="500"
                        color="#808080"
                    >{`Want to sell a work on Primary Yellow?`} <Button noStyle textDecoration="underline" fontWeight="500" color="#808080">Sell with Primary Yellow</Button>
                    </Text>
                </View>
            </View>
            <View>
                <AboutTheWork />
            </View>
            {isOfferModal && <OfferModal setModal={setIsOfferModal}/>}
        </View>
    )
}

const BackNavigator = ({data, activeIndex, setActiveIndex, isHover}: any) => {

    const onClick = () => {
        if (activeIndex === 0) {
            setActiveIndex(data.posts.length - 1)
        } else {
            setActiveIndex(activeIndex - 1)
        }
    }

    return (
        <StyledBackNavigator isHover={isHover}>
            <Button noStyle onClick={onClick}>
                <LeftIcon height={32} width={32}/>
            </Button>
        </StyledBackNavigator>
    )
}

const NextNavigator = ({data, activeIndex, setActiveIndex, isHover}: any) => {

    const onClick = () => {
        if (activeIndex === (data.posts.length - 1)) {
            setActiveIndex(0)
        } else {
            setActiveIndex(activeIndex + 1)
        }
    }

    return (
        <StyledNextNavigator isHover={isHover}>
            <Button noStyle onClick={onClick}>
                <RightIcon height={32} width={32}/>
            </Button>
        </StyledNextNavigator>
    )
}

const ViewingRoom = () => {
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const post = usePostStore((state: any) => state.post)

    const [activeIndex, setActiveIndex] = useState(0)
    const [activePost, setActivePost] = useState<any>(post.posts[0])

    useEffect(() => {
        console.log(activeIndex, post.posts[activeIndex])
        setActivePost(post.posts[activeIndex])
    }, [activeIndex])
    

    const onClickClose = () => {
        setPdp('show')
    }

    console.log(activePost)


    return (
        <StyledViewingRoomContainer>
            <SlideMenu>
                <PurchaseShowArtwork post={activePost} />
            </SlideMenu>
            <StyledViewingRoom>
                <View position="absolute" right="24px" top="24px" zIndex="2">
                    <Button noStyle onClick={onClickClose}>
                        <XIcon height={28} width={28}/>
                    </Button>
                </View>
                <View position="absolute" left="24px" top="24px" zIndex="2">
                    <View display="flex" flexDirection="column" gap="4px">
                        <View>
                            <ProfilePicture account={post.owner} username fontSize="18px"/>
                        </View>
                        <View>
                            <Text fontSize="18px">{post.title}</Text>
                        </View>
                        <View>
                            <Text fontSize="18px">{toCurrency(activePost?.metadata?.price)}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <GalleryTemplates  payload={activePost} height={'100vh'} photoHeight={'400px'}/>
                </View>
            </StyledViewingRoom>
            {post.posts.length > 1 && <View position="absolute" right="72px" bottom="12px">
                <View display="inline-flex" alignItems="center" gap="4px">
                    <BackNavigator data={post} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                    <NextNavigator data={post} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
                </View>
            </View>}
        </StyledViewingRoomContainer>
    )
}

export default ViewingRoom