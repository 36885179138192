import styled from 'styled-components'


interface BioContainerProps {

}


export const StyledBioContainer = styled.div<BioContainerProps>`
    
`

interface BioProps {

}


export const StyledBio = styled.div<BioProps>`
    display: flex;
    flex-direction: column;
    gap: 6px;
`

interface NameContainerProps {

}


export const StyledNameContainer = styled.div<NameContainerProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
`


interface BioNameProps {

}


export const StyledBioName = styled.div<BioNameProps>`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    width: 98%;
    margin: 0;
`

interface FollowingContainerProps {

}


export const StyledFollowingContainer = styled.div<FollowingContainerProps>`
    padding: 4px 0px;
`

interface FollowingProps {

}


export const StyledFollowing = styled.div<FollowingProps>`
    display: inline-flex;
    gap: 12px;
`

interface RoleContainerProps {

}


export const StyledRoleContainer = styled.div<RoleContainerProps>`
`

interface UserBioContainerProps {

}


export const StyledUserBioContainer = styled.div<UserBioContainerProps>`
    padding: 12px 0px 4px 0px;
    font-size: 13px;
`

interface BioButtonsContainerProps {

}


export const StyledBioButtonsContainer = styled.div<BioButtonsContainerProps>`
    display: inline-flex;
    gap: 4px;
    width: 100%;
`

interface ProfileFollowProps {

}


export const StyledProfileFollow = styled.div<ProfileFollowProps>`
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-wrap: wrap;

    @media (min-width: 768px) {
        
    }
    
    @media (min-width: 1600px) {
    }
`










