import { useEffect, useState } from "react"
import { useResponsiveStore } from "../../../stores/web.store"
import { StyledUrlContainer } from "./styled"
import Input from "../Input"
import { Desktop, Laptop } from "../../../utils/responsive.util"

interface UrlProps {
    payload: any
    setPayload: (payload: any) => void
    urlKey: string
    placeholder?: string
    required?: boolean
    validationMessage?: string
}

export const Url = ({
    payload, 
    setPayload, 
    urlKey,
    placeholder,
    required = false,
    validationMessage = 'This field is required'
}: UrlProps) => {
    const mode = useResponsiveStore((state: any) => state.mode)

    const [message, setMessage] = useState<any>(null)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    useEffect(() => {
        if (message && !payload[urlKey]) {
            setMessage(null)
        }
    }, [isInvalid, urlKey, payload])

    const onChange = (e: any) => {
        setPayload({...payload, [urlKey]: e.target.value})
    }

    const onBlur = () => {
        if (required && !payload[urlKey]) {
            setMessage(validationMessage)
            setIsInvalid(true)
            return
        }
        setIsInvalid(false)
    }

    return (
        <StyledUrlContainer>
            <Input
                placeholder={placeholder || urlKey}
                value={payload[urlKey]}
                onChange={onChange}
                onBlur={onBlur}
                padding={[Desktop, Laptop].includes(mode) ? '12px 0px' : '16px 0px'}
                fontSize={'16px'}
                width={'100%'}
                message={message}
                setMessage={setMessage}
                invalid={isInvalid}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
        </StyledUrlContainer>
    )
}

export default Url
