import styled from 'styled-components'


interface DiscoverPeoplePostContainerProps {

}

export const StyledDiscoverPeoplePostContainer = styled.div<DiscoverPeoplePostContainerProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0px;
    align-items: center;
    position: relative;
`
