import styled from 'styled-components'

interface CreatePostContainerProps {

}


export const StyledCreatePostContainer  = styled.div<CreatePostContainerProps>`

`

interface BackContainerProps {

}

export const StyledBackContainer = styled.div<BackContainerProps>`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
`

interface BackProps {
    theme: any;
}

export const StyledBack = styled.div<BackProps>`
    padding: 12px;
    background: ${props => props.theme.BackgroundColorTheme};
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.BorderColor};
`

interface CreatePostTitleSectionProps {

}

export const StyledCreatePostTitleSection = styled.div<CreatePostTitleSectionProps>`
    padding: 4px;
    @media (min-width: 768px) {
        padding: 48px 24px 0px 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface CreatePostProps {

}

export const StyledCreatePost = styled.div<CreatePostProps>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    padding: 0;
    text-align: center;
`

interface SelectedPostContainerProps {

}

export const StyledSelectedPostContainer = styled.div<SelectedPostContainerProps>`

`

interface PostTitleContainer {

}

export const StyledPostTitleContainer = styled.div<PostTitleContainer>`
    padding: 0px 0px 6px 0px;
`
