import styled from "styled-components"


interface ShareModalContainerProps {

}

export const StyledShareModalContainer = styled.div<ShareModalContainerProps>`
    width: 500px;
    background: #fff;
    border-radius: 12px;
    position: relative;
    margin: auto;

    padding: 20px;

    -webkit-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
    box-shadow: 9px 10px 20px -16px rgba(0,0,0,0.75);
`

interface ShareModalProps {
    
}

export const StyledShareModal = styled.div<ShareModalProps>`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`

interface ShareContainerProps {
    
}

export const StyledShareContainer = styled.div<ShareContainerProps>`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

interface CloseModalContainerProps {

}

export const StyledCloseModalContainer = styled.div<CloseModalContainerProps>`
    position: absolute;
    right: 12px;
    top: 12px;
`