import styled from "styled-components";


interface EmailProps {

}


export const StyledEmailContainer = styled.div<EmailProps>`
    width: 100%;
    position: relative;

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
`