import { View } from "../../../components/View"
import { DiscoverPeople } from "../../../sections/DiscoverPeople"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"


const People = () => {
    return (
        <DashboardLayout>
            <View padding="48px 0px">
                <View width="400px" margin="auto">
                    <DiscoverPeople title="Suggested" limit={20} />
                </View>
            </View>
        </DashboardLayout>
    )
}

export default People