import styled from 'styled-components'


interface ModalContainerProps {
    position?: string;
    top?: string;
    left?: string;
}


export const StyledModalContainer = styled.div<ModalContainerProps>`
    background: ${'rgba(0, 0, 0, .4)'};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5;
`


interface ModalProps {
    width?: any;
    height?: any;
    margin?: any;
}


export const StyledModal = styled.div<ModalProps>`
    width: ${props => props.width || '100%'};
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    border-radius: 20px;
    
    
    

    @media (min-width: 768px) {
        height: ${props => props.height || '90vh'};
        width: ${props => props.width || 'fit-content'};
        margin: ${props => props.margin || '5vh auto'};

    }
    
    @media (min-width: 1600px) {
    }
`

interface CreatePostContainerProps {
    theme: any;
}


export const StyledCreatePostContainer = styled.div<CreatePostContainerProps>`
    border-bottom: solid 1px ${props => props.theme.BorderColor};
    position: fixed;
    width: 100%;
    padding: 24px 0px;
    background: ${props => props.theme.BackgroundColorTheme};
    top: 0;
    

    @media (min-width: 768px) {
        position: relative;
    }
    
    @media (min-width: 1600px) {
    }
`

interface ModalContentContainerProps {
    theme: any;
}


export const StyledModalContentContainer = styled.div<ModalContentContainerProps>`
    height: max-content;
    width: 100%;
    margin: auto;
    position: relative;
    padding: 96px 0px 24px 0px;
    
    background: ${props => props.theme.BackgroundColorTheme};
    overflow: hidden;
    overflow-y: scroll;
    
    @media (min-width: 768px) {
        width: 50%;
        overflow-y: hidden;
        border-radius: 20px;
        padding: 0;
    }

    @media (min-width: 1024px) {

    }
`

interface ModalContentProps {
    height?: string;
    background?: string;
    theme: any;
}


export const StyledModalContent = styled.div<ModalContentProps>`

    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: ${props => props.theme.BackgroundColorTheme};
    padding: 24px;
    display: block;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
    
    
    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);;
        height: max-content;
        padding: 30px 60px;

    }

    @media (min-width: 1024px) {
    }
`

interface ModalContentProps {
    height?: string;
    background?: string;
    theme: any
}


export const StyledCreateCard = styled.button<ModalContentProps>`
    cursor: pointer;

    height: fit-content;
    // max-height: 35vh;
    border-radius: 10px;
    border: none;
    background: ${props => props.theme.BackgroundColorTheme};
    padding: 12px 12px;
    margin: auto;

    -webkit-box-shadow: 2px 2px 10px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 10px -9px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 10px -9px rgba(0,0,0,0.75);
    border-radius: 10px;

    @media (min-width: 768px) {
        
        
        

    }

    @media (min-width: 1024px) {
    }
    
    
    &:hover {
        background: #ECECEC;
    }

    &:active:hover {
        opacity: .5;
    }
`


