import { useEffect, useRef } from "react"
import { StyledSlideMenu, StyledSlideMenuContainer } from "./styled"
import { useDashboardMenuExpandedStore, useSlideMenuStore } from "../../stores/web.store";
import { Inbox } from "../../components/Inbox";
import { Search } from "../../components/Search";
import { Notifications } from "../../components/Notifications";
import { Filters } from "../Filters";


const useOutsideAlerter = (ref: any) => {
    const resetSlideMenu = useSlideMenuStore((state: any) => state.resetSlideMenu)
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        // Get the clicked element and check if it or any of its parents have menu item data attributes
        const target = event.target;
        const isMenuItemClick = target.hasAttribute('data-message-item') || 
                               target.hasAttribute('data-search-item') ||
                               target.hasAttribute('data-notifications-item') ||
                               target.closest('[data-message-item="true"]') !== null ||
                               target.closest('[data-search-item="true"]') !== null ||
                               target.closest('[data-notifications-item="true"]') !== null;

        // If it's not a menu item click and we clicked outside the menu, reset
        if (!isMenuItemClick && ref.current && !ref.current.contains(target)) {
          resetSlideMenu()
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}


const SlideMenu = () => {
    const wrapperRef = useRef(null);
    const slideMenu = useSlideMenuStore((state: any) => state.slideMenu)
    const isExpanded = useDashboardMenuExpandedStore((state: any) => state.isExpanded)

    useOutsideAlerter(wrapperRef);
    
    return (
        <StyledSlideMenuContainer 
          slideMenu={slideMenu} 
          isExpanded={isExpanded} 
          menuKey={slideMenu.key}
        >
            <StyledSlideMenu 
              slideMenu={slideMenu} 
              ref={wrapperRef}
              width={slideMenu.key === 'inbox' ? '100%' : '400px'} 
            >
                {slideMenu.key === 'inbox' && <Inbox />}
                {slideMenu.key === 'search' && <Search />}
                {slideMenu.key === 'notifications' && <Notifications />}
                {slideMenu.key === 'filters' && <Filters />}
            </StyledSlideMenu>
        </StyledSlideMenuContainer>
    )
}

export default SlideMenu