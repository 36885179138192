
import { PhotoOverlap } from "../PhotoOverlap"
import { Text } from "../Text"
import { StyledLikesContainer } from "./styled"
import { useState } from "react"
import { Button } from "../Button"
import { LikesModal } from "../Modal/LikesModal"


const Likes = ({postData, noCount}: any) => {
    const [isModal, setIsModal] = useState(false)

    const onClick = () => {
        setIsModal(true)
    }

    return (
        <StyledLikesContainer>
            {postData.total_likes > 0 && <Button onClick={onClick} noStyle textAlign="left" gap="16px">
                {postData.likes.length > 0 && <PhotoOverlap data={postData.likes}/>}
                {!noCount && !postData.privacy_settings?.is_hidden_likes && postData.total_likes > 0 && <Text fontWeight="500" fontSize="15px">{postData.total_likes} {postData.total_likes > 1 ? 'likes' : 'like'}</Text>}
            </Button>}
            {isModal && <LikesModal data={postData.likes} setIsModal={setIsModal}/>}
        </StyledLikesContainer>
    )
}

export default Likes