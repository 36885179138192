import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import api from "../../../services/api.service"
import { usePostStore, useProductDetailPageStore, useThemeStore, useViewNavigatorStore } from "../../../stores/web.store"
import { View } from "../../../components/View"
import { Button } from "../../../components/Button"
import {ReactComponent as ChevronLeftIcon} from '../../../img/chevronLeft.svg'
import { Text } from "../../../components/Text"
import { ImageGallery } from "../../ImageGallery"
import { StyledMainContent, StyledMainContentContainer } from "./styled"
import { key2Value, toAuctionState, toCurrency, toDate, toDateDiff } from "../../../utils/app.util"
import {ReactComponent as ArtworkIcon} from '../../../img/artwork.svg'
import { Divider } from "../../Divider"
import { ShippingAndFees } from "../../../sections/ShippingAndFees"
import GalleryInfo from "../../GalleryInfo/GalleryInfo"
import { SaveButton } from "../../Button/Save"
import { ViewInRoomButton } from "../../Button/ViewInRoom"
import { ShareButton } from "../../Button/Share"
import { AboutTheWork } from "../../../sections/AboutTheWork"
import { ArtistOtherWorks } from "../../../sections/OtherWorks/Artists"
import { GalleryOtherWorks } from "../../../sections/OtherWorks/Gallery"
import { RecentlyViewed } from "../../../sections/RecentlyViewed"
import { AlertButton } from "../../Button/Alert"
import { BidModal } from "../../Modal/BidModal"
import { Bid } from "../../Button/Bid"


const Auction = () => {
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const theme = useThemeStore((state: any) => state.theme)
    const post = usePostStore((state: any) => state.post)
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const [isBidModal, setIsBidModal] = useState(false)
    const {id} = useParams()
    

    const [timeValue, setTimeValue] = useState<any>('')
    const [auctionState, setAuctionState] = useState<any>(post.parent_post.state)

    useEffect(() => {
        toAuctionState(post.parent_post, setTimeValue, setAuctionState)
    }, [])
    
    useEffect(() => {
        setActiveView('overview')
        const fetch = async () => {
            const resp = await api.get(`/posts/${id}`)
            setPost(resp.data.data)
        }

        if (!post && id)
            fetch()
        
    }, [])

    const onClickBack = () => {
        setPost(null)
        setPdp(null)
    }

    // const countdown = (post: any) => {
    //     setInterval(function () {
    //         const now = new Date()
    //         const start_datetime = new Date(post.parent_post.metadata.start_datetime)
    //         const end_datetime = new Date(post.parent_post.metadata.end_datetime)
    //         if (now >= end_datetime) {
    //             setTimeValue(toDateDiff(post.parent_post.metadata.start_datetime, true))
    //             setAuctionStatus('ended')
    //         }

    //         if (now < start_datetime) {
    //             setTimeValue(toDateDiff(post.parent_post.metadata.start_datetime, true))
    //             setAuctionStatus('upcoming')
    //         }

    //         if (now >= start_datetime && now < end_datetime) {
    //             setTimeValue(toDateDiff(post.parent_post.metadata.end_datetime, true))
    //             setAuctionStatus('active')
    //         }
            
    //     }, 1000);
    // }

    if (post === null) return <div />

    return (
        <View>
            <View>
                <Button noStyle onClick={onClickBack}>
                    <ChevronLeftIcon height={16} width={16}/> 
                    <Text fontWeight="500">{`Back to Auctions`}</Text>
                </Button>
            </View>
            <View>
                <StyledMainContentContainer>
                    <StyledMainContent>
                        <View padding="12px 0px">
                            <ImageGallery data={post} setPost={setPost} height="600px"/>
                            <View padding="12px">
                                <View 
                                    display="inline-flex" 
                                    alignItems="center" 
                                    width="100%" 
                                    justifyContent="center"
                                    gap="12px"
                                >
                                    <SaveButton data={post}/>
                                    <ViewInRoomButton data={post} />
                                    <ShareButton data={post} />
                                </View>
                            </View>
                        </View>
                        <View padding="12px">
                            <Text fontSize="24px" fontWeight="500">{post.owner.first_name} {post.owner.last_name}</Text>
                            <View color="grey" padding="12px 0px">
                                <Text fontSize="24px" fontWeight="500">{post.title}, {post.date || '2024'}</Text>
                                <View padding="12px 0px" display="flex" flexDirection="column" gap="4px">
                                    <View display="inline-flex" alignItems="center">
                                        <Text textTransform="capitalize">{post.metadata.medium}</Text>
                                        <Text></Text>
                                    </View>
                                    <Text>{post.metadata.dimensions || '00 x 00 x 00'}</Text>
                                    <View display="inline-flex" alignItems="center" gap="4px">
                                        <ArtworkIcon stroke="grey" fill="grey" height={15} width={15}/>
                                        <Text textTransform="capitalize">{key2Value(post.metadata.rarity)}</Text>
                                    </View>
                                </View>
                            </View>
                            <View padding="4px 0px">
                                <Text>Estimated Value: {toCurrency(post.metadata.estimated_value, post.metadata.estimated_value_currency || 'usd')}</Text>
                            </View>
                            <View>
                                <View display="inline-flex" alignItems="center" width="100%">
                                    <Text fontWeight="500" fontSize="24px" width="50%">{`Starting Bid`}</Text>
                                    <Text fontWeight="500" fontSize="24px" width="50%" textAlign="right">{toCurrency(post.metadata.starting_bid, post.metadata.starting_bid_currency || 'usd')}</Text>
                                </View>
                            </View>
                            <View color="grey" padding="12px 0px">
                                <Text>{`This auction has a buyer’s premium`}</Text>
                                <Text padding="4px 0px 0px 0px">{`Shipping, fees, and additional taxes may apply`}</Text>
                            </View>
                            <View padding="12px 0px">
                                <div style={{padding: '12px 0px 0px 0px'}}>
                                    <Bid setIsModal={setIsBidModal} isModal={isBidModal} auctionState={auctionState}/>
                                </div>
                            </View>
                            <View padding="12px 0px">
                                <View display="flex" flexDirection="column" gap="4px">
                                    {auctionState === 'ended' && <Text textAlign="center" width="100%">{`Auction Ended`}</Text>}
                                    {auctionState === 'upcoming' && <Text textAlign="center" width="100%">{timeValue}</Text>}
                                    {auctionState === 'active' && <Text textAlign="center" width="100%">{`Auction Live`}</Text>}
                                    {['ended', 'active'].includes(auctionState) && <Text textAlign="center" width="100%">Ended {toDate(post.parent_post.metadata.end_datetime)}</Text>}
                                    {['upcoming'].includes(auctionState) && <Text textAlign="center" width="100%">Live {toDate(post.parent_post.metadata.start_datetime)}</Text>}
                                </View>
                            </View>
                            <Divider />
                            <View>
                                <ShippingAndFees />
                            </View>
                            <Divider />
                            <View padding="12px 0px">
                                <View display="inline-flex" alignItems="center" width="100%">
                                    <Text width="fit-conent">{`Get Notifications for similar works`}</Text>
                                    <View textAlign="right" flexGrow="1">
                                        <AlertButton />
                                    </View>
                                </View>
                            </View>
                            <Divider />
                            <View padding="12px 0px">
                                <Text 
                                    fontWeight="500"
                                    color="#808080"
                                >{`Want to sell a work on Primary Yellow?`} <Button noStyle textDecoration="underline" fontWeight="500" color="#808080">Sell with Primary Yellow</Button>
                                </Text>
                            </View>
                        </View>
                        <View>
                            <AboutTheWork />
                        </View>
                    </StyledMainContent>
                    <View padding="48px 0px">
                        <ArtistOtherWorks post={post}/>
                    </View>
                    {post.gallery && <View padding="0px 0px 48px 0px">
                        <GalleryOtherWorks post={post}/>
                    </View>}
                    <View>
                        <RecentlyViewed />
                    </View>
                </StyledMainContentContainer>
            </View>
            {isBidModal && <BidModal setModal={setIsBidModal}/>}
        </View>
    )
}

export default Auction