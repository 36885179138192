import { StyledImage } from "./styled"


type ImageProps = {
    src: string;
    display?: string;
    height?: string;
    maxHeight?: string;
    width?: string;
    borderRadius?: string;
    objectFit?: string;
    border?: string;
    zoom?: number
    aspectRatio?: string;
    isNoEffect?: boolean;
    imageRef?: any;
    style?: any
}

const Image = ({src, imageRef, height, maxHeight, width, border, objectFit, borderRadius, zoom, aspectRatio, isNoEffect, style}: ImageProps) => {
    return (
        <StyledImage 
            ref={imageRef}
            src={src} 
            height={height} 
            maxHeight={maxHeight}
            width={width}
            border={border}
            objectFit={objectFit}
            borderRadius={borderRadius}
            zoom={zoom}
            aspectRatio={aspectRatio}
            isNoEffect={isNoEffect}
            style={style}
        />
    )
}

export default Image