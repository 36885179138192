import { Map as MapComponent, Show } from "../../components/Map"
import { useEffect, useState } from "react"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout";
import api from "../../services/api.service";
import { useEffectOnce } from "../../utils/app.util";
import { PageLoader } from "../../components/Loader/PageLoader";
import { useAccessStore } from "../../stores/web.store";


const Map = () => {
  const access = useAccessStore((state: any) => state.access)
  const [shows, setShows] = useState<Show[]>();


  useEffectOnce(() => {
    const fetch = async () => {
      const resp = await api.post(`/search/artmap`, {query: {}})
      setShows(resp.data)
    }

    fetch()
  })

  // Make sure your Google Maps API key has Maps ID enabled
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

  if (shows === undefined) return <PageLoader />

  if (!access) return (
    <MapComponent
          shows={shows}
          googleMapsApiKey={googleMapsApiKey}
        />
  )
  
  return (
    <DashboardLayout height="100vh">
        <MapComponent
          shows={shows}
          googleMapsApiKey={googleMapsApiKey}
        />
    </DashboardLayout>
  )
}

export default Map