import { ArtworkFilters } from "../../components/Filters/ArtworksFilters"
import { Title } from "../../components/Page/Title"
import { BrowseBy } from "../../sections/BrowseBy"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { StyledCategory } from "../styled"
import {Artworks as ArtworksSection} from '../../sections/Artworks'
import { useArtworks } from "../../hooks/api.hook"
import { useEffect, useState } from "react"
import { useAccountStore, useFiltersStore } from "../../stores/web.store"
import { View } from "../../components/View"
import api from "../../services/api.service"




const Artworks = () => {
    const account = useAccountStore((state: any) => state.account) 
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const [apiResp, setApiResp] = useState<any>({})
    const [artworks, setArtworks] = useState<any>({})

    const {data} = useArtworks({query: {}})

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
            setArtworks(data.data)
        }
    }, [data])
    

    useEffect(() => {
        const fetch = async () => {
            const {query, filters} = filterStore
            const resp = await api.post('/search/artworks', {query: {query, ...filters}})
            setArtworks(resp.data)
            setFilterStore({...filterStore, loaded: false})
        }

        if (filterStore.loaded)
            fetch()
    }, [filterStore])

    if (account === null) return <div />

    
    if (!apiResp.is_loaded) return <div />

    return (
        <DashboardLayout>
            <View padding="24px">
                <View display="flex" flexDirection="column" gap="24px">
                    <View>
                        <Title 
                            title={'Artworks'}
                        />
                    </View>
                    
                    <View>
                        <ArtworkFilters setData={setArtworks} resetData={apiResp.data} />
                    </View>
                    <View>
                        <ArtworksSection data={artworks} isResultsPage/>
                    </View>
                </View>
                
            </View>
        </DashboardLayout>
    )
}

export default Artworks