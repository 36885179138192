import { useEffect, useState } from "react"
import { useResponsiveStore } from "../../../stores/web.store"
import { StyledEmailContainer } from "./styled"
import Input from "../Input"
import { Desktop, Laptop } from "../../../utils/responsive.util"

export const Email = ({payload, setPayload}: any) => {
    const mode = useResponsiveStore((state: any) => state.mode)

    const [validationMessage, setValidationMessage] = useState<any>(null)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    useEffect(() => {
        if (validationMessage && !payload.email) {
            setValidationMessage(null)
        }
    }, [isInvalid])

    const onChange = (e: any) => {
        setPayload({...payload, email: e.target.value})
    }

    const onBlur = () => {
        const email = payload.email

        if (email && !email.includes('@') && !email.includes('.')) {
            setValidationMessage('Invalid Email Address.')
            setIsInvalid(true)
            return
        }

        if (isInvalid && email && email.includes('@') && email.includes('.')) {
            setIsInvalid(false)
            return
        }
    }


    return (
        <StyledEmailContainer>
            <Input
                type={'email'}
                placeholder={'Email Address'}
                padding={[Desktop, Laptop].includes(mode) ? '12px 0px' : '16px 0px'}
                fontSize={'16px'}
                width={'100%'}
                value={payload.email || ''}
                onChange={onChange}
                onBlur={onBlur}
                message={validationMessage}
                setMessage={setValidationMessage}
                invalid={isInvalid}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
        </StyledEmailContainer>
    )
}

export default Email