import styled from 'styled-components'


interface ExplorePostsContainerProps {
    height?: string;
    overflow?: string
    ref?: any;
}


export const StyledExplorePostsContainer = styled.div<ExplorePostsContainerProps>`
    padding: 0px 0px;
    overflow-y: ${props => props.overflow || 'auto'};
    height: ${props => props.height || 'auto'};

    @media (min-width: 768px) {
        padding: 0px;
        
    }

    @media (min-width: 1024px) {

    }
`

interface ExplorePostsProps {

}


export const StyledExplorePosts = styled.div<ExplorePostsProps>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 27px;
    width: calc(100% - 12px);

    @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    @media (min-width: 1024px) {

    }
`

interface ShowMorePostsProps {

}


export const StyledShowMorePosts = styled.div<ShowMorePostsProps>`
    text-align: center;
    width: 100%;
    padding: 0px 0px 28px 0px;
`

interface QuickFiltersContainerProps {

}


export const StyledQuickFiltersContainer = styled.div<QuickFiltersContainerProps>`
    overflow-x: auto;
    padding: 4px 0px;
    @media (min-width: 768px) {
        display: block;
    }

    @media (min-width: 1024px) {

    }
`


