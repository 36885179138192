import { useEffect, useState } from "react"
import { ShowFilters } from "../../components/Filters/ShowFilters"
import { Title } from "../../components/Page/Title"
import { View } from "../../components/View"
import { Featured } from "../../sections/Featured"
import { DashboardLayout } from "../../sections/Layout/DashboardLayout"
import { ViewNavigator } from "../../sections/ViewNavigator"
import { useAccountStore, useFiltersStore, usePostStore, useProductDetailPageStore, useViewNavigatorStore } from "../../stores/web.store"
import { useAllPosts, useFeaturedShows, useShows } from "../../hooks/api.hook"
import InfiniteScroll from "react-infinite-scroll-component"
import api from "../../services/api.service"
import { StyledShow, StyledShowDetails, StyledShows, StyledShowsContainer } from "./styled"
import { Post } from "../../components/Post"
import { QuickFilters } from "../../components/Filters/QuickFilters"
import { SearchBar } from "../../components/SearchBar"
import { Text } from "../../components/Text"
import { toDate, toDateRange } from "../../utils/app.util"
import { Buttons } from "../../components/Button/Buttons"
import Share from "../../components/Button/Share/Share"
import Like from "../../components/Button/Like/Like"
import { Comment } from "../../components/Button/Comment"
import { ProfilePicture } from "../../components/ProfilePicture"
import { Button } from "../../components/Button"
import {ReactComponent as MapIcon} from '../../img/map.svg'
import { useNavigate } from "react-router-dom"


type ShowsQuickFiltersProps = {
    posts: any;
    setPosts: any;
    setHasMore?: any;
    setOffset?: any;
}


const ShowsQuickFilters = ({posts, setPosts, setHasMore, setOffset}: ShowsQuickFiltersProps) => {
    const [selectedFilter, setSelectedFilter] = useState('all')

    useEffect(() => {
        const fetch = async () => {
            const filter = (selectedFilter === 'all') ? {} : {type: selectedFilter}
            
            
            const resp = await api.post(`/search/shows`, {query: filter, offset: 0})
            setPosts(resp.data)
            setHasMore(resp.data.length > 0) 
            setOffset(0);
        }

        fetch()

    }, [selectedFilter])

    return (
        <QuickFilters 
            selectedFilter={selectedFilter} 
            setSelectedFilter={setSelectedFilter} 
            includeAll 
            includeAtrists 
            includePatrons 
            includeGalleries 
            includeMuseums 
            includePastShows 
        />
    )
}



const FeaturedShows = () => {
    const setPost = usePostStore((state: any) => state.setPost)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const [api, setApi] = useState<any>({})
    const {data} = useFeaturedShows({query: {}})

    const onClick = (post: any) => {
        setPost(post)
        setPdp('show')
    }
    
    

    useEffect(() => {
        if (data && data.is_loaded) {
            setApi(data)
        }
    }, [data])


    if (!api.data) return <div />

    return (
        <Featured 
            type="Shows" 
            data={api.data} 
            columns={api.data.length}
            height="600px"
            onClick={onClick}
        />
    )
}

const ShowDetails = ({show}: any) => {
    return (
        <StyledShowDetails className="show-details">
            <View padding="24px">
                <View display="flex" flexDirection="column" gap="12px">
                    <View>
                        <Text fontSize="20px" fontWeight="500">{show.title}</Text>
                    </View>
                    <View>
                        <Text fontSize="16px" fontStyle="italic">{show.caption}</Text>
                    </View>
                    <View>
                        <Text fontSize="14px">{toDateRange(show.metadata.start_datetime, show.metadata.end_datetime)}</Text>
                    </View>
                </View>
            </View>
        </StyledShowDetails>
    )
}

const Shows = () => {
    const navigate = useNavigate()
    const setPost = usePostStore((state: any) => state.setPost)
    const setActiveView = useViewNavigatorStore((state: any) => state.setActiveView)
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const account = useAccountStore((state: any) => state.account) 
    const [apiResp, setApiResp] = useState<any>({})
    const setFilterStore = useFiltersStore((state: any) => state.setFilterStore) 
    const filterStore = useFiltersStore((state: any) => state.filterStore) 
    const {data} = useShows({query: {}})

    const [showPosts, setShowPosts] = useState<any>([])

    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    

    useEffect(() => {
        if (data && data.is_loaded) {
            setApiResp(data)
            setShowPosts(data.data)
        }
        setActiveView('artists')
    }, [data])

    const fetchMoreData = async () => {
        const newOffset = offset + 1
        const resp = await api.post(`/search/shows`, {query: {}, offset: newOffset})

        setShowPosts([...showPosts, ...resp.data])
        setHasMore(resp.data.length > 0) 
        setOffset(newOffset);
    };

    const onClick = (post: any) => {
        setPost(post)
        setPdp('show')
    }

    useEffect(() => {
        const fetch = async () => {
            const {query, filters} = filterStore
            const resp = await api.post('/search/shows', {query: {query, ...filters}})
            setShowPosts(resp.data)
            setFilterStore({...filterStore, loaded: false})
        }

        if (filterStore.loaded)
            fetch()
    }, [filterStore])

    const fetch = (value: string) => {
        setFilterStore({...filterStore, query: value, loaded: true})
    }

    const onClickMap = () => {
        navigate('/artmap')
    }

    if (account === null) return <div />

    
    if (!apiResp.data) return <div />

    return (
        <DashboardLayout>
            <View>
                <View padding="24px">
                    <View>
                        <Title 
                            title={'Shows'}
                        />
                    </View>
                    <View textAlign="center">
                        <View display="flex" flexDirection="column" gap="20px" width="600px" margin="auto" position="relative">
                            <View width="600px" margin="auto">
                                <SearchBar setData={setShowPosts} fetch={fetch} resetData={apiResp.data} isAISearch noImageSearch/>
                            </View>
                            <View textAlign="center" overflowX="auto" width="600px">
                                <ShowsQuickFilters 
                                    posts={showPosts} 
                                    setPosts={setShowPosts} 
                                    setHasMore={setHasMore} 
                                    setOffset={setOffset} 
                                />
                                
                            </View>
                            <View position="absolute" right="0px" top="6px">
                                <Button noStyle onClick={onClickMap}>
                                    <MapIcon fill="#000"/>
                                </Button>
                            </View>
                        </View>
                    </View>
                </View>
                <View padding="0px 0px 24px 0px">
                    <View height="80vh" overflowY="scroll">
                        <StyledShowsContainer id="Shows">
                            <InfiniteScroll
                                dataLength={showPosts.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<div />}
                                scrollableTarget="Shows"
                            >
                                
                                <StyledShows>
                                    {showPosts.map((show: any) => {
                                        return (
                                            <StyledShow>
                                                <Post 
                                                    data={show} 
                                                    type={show.type} 
                                                    setPost={setPost} 
                                                    onClick={() => onClick(show)} 
                                                    borderRadius="10px" 
                                                    height="75vh" 
                                                    noIcon
                                                >
                                                    <ShowDetails show={show}/>
                                                </Post>
                                                <View position="absolute" right="-48px" bottom="0">
                                                    <Buttons flexDirection="column">
                                                        <ProfilePicture account={show.owner} photo />
                                                        <Like data={show} />
                                                        <Comment />
                                                        <Share data={show} noTitle/>
                                                    </Buttons>
                                                </View>
                                            </StyledShow>
                                        )
                                    })}
                                </StyledShows>
                            </InfiniteScroll>
                        </StyledShowsContainer>
                    </View>
                </View>
            </View>
        </DashboardLayout>
    )
}

export default Shows