import styled from 'styled-components'


interface ModalContainerProps {
    position?: string;
    top?: string;
    left?: string;
    background?: string;
}


export const StyledModalContainer = styled.div<ModalContainerProps>`
    background: ${props => props.background  || 'rgba(0, 0, 0, .4)'};
    position: ${props => props.position || 'fixed'};
    top: ${props => props.top || '0'};
    left: ${props => props.left || '0'};
    height: 100vh;
    width: 100%;
    z-index: 5;


`


interface ModalProps {
    width?: any;
    height?: any;
    margin?: any;
}


export const StyledModal = styled.div<ModalProps>`
    width: ${'100%'};
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: ${props => props.margin || '0'};
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        width: ${props => props.width || '80%'};
        max-width: ${props => props.width || '1200px'};
        margin: ${props => props.margin || '0 auto'};
    }
    
    @media (min-width: 1600px) {
    }
`

interface ModalContentContainerProps {

}


export const StyledModalContentContainer = styled.div<ModalContentContainerProps>`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0;
    
    @media (min-width: 768px) {
        padding: 0 24px;
    }

    @media (min-width: 1024px) {

    }
`

interface ModalContentProps {
    height?: string;
    background?: string;
    padding?: string;
    borderRadius?: string;
}


export const StyledModalContent = styled.div<ModalContentProps>`
    border-radius: ${props => props.borderRadius || '0px'};
    height: ${props => props.height || 'calc(100vh - 48px)'};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    margin: 24px 0;
    background: ${props => props.background || '#fff'};

    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        border-radius: ${props => props.borderRadius || '8px'};
        padding: ${props => props.padding || '0'};
        height: ${props => props.height || 'calc(100vh - 48px)'};
        max-height: 900px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (min-width: 1024px) {
    }
`
