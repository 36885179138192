import Theme from "./Theme";
import "./styles.css";
import _debounce from "lodash/debounce"
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { StyledMarkdown, StyledMarkdownContainer } from "./styled";
import { useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from '@lexical/html'; 
import { $createParagraphNode, $createTextNode, $getRoot, COMMAND_PRIORITY_HIGH, KEY_ENTER_COMMAND } from "lexical";
import { useResponsiveStore } from "../../stores/web.store";
import api from "../../services/api.service";
import { View } from "../View";
import { Text } from "../Text";
import { ProfilePicture } from "../ProfilePicture";


const Placeholder = ({value}: any) => {
    return <div className="editor-placeholder">{value || 'Write a caption'}</div>;
}



const Markdown = ({
    uid,
    editorStateRef, 
    data, 
    singleLine, 
    onChange, 
    readOnly, 
    placeholder, 
    noTools, 
    allowAttachment,
    border,
    borderTop,
    borderRight,
    borderLeft,
    borderBottom,
    borderRadius,
    emoji,
    handleEnterCommand
}: any) => {
    const width = useResponsiveStore((state: any) => state.width)
    // const _onChange = (editorState: any) => editorStateRef.current = editorState
    const [suggestedAccounts, setSuggestedAccounts] = useState<any>([])
    const [activeWord, setActiveWord] = useState<string>('')

    const handleDebounceFn = async (value: any) => {
        const username = value.replace('@', '').trim()
        if (username.length === 0) return true

        const resp = await api.post('/search/accounts', {query: {query: username}})
        setSuggestedAccounts(resp.data)
        return true
        
    }

    const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);


    const _onChange = async (editorState: any) => {
        
        const msg = editorState.read(() => $getRoot().getTextContent())

        if (msg.endsWith(" ")) {
            setActiveWord(''); // Exit mention logic
            setSuggestedAccounts([])
            return;
        }

        const actWord = msg.split(' ').pop()
        setActiveWord(actWord)
        

        const isMention = actWord?.startsWith('@')

        if (isMention) {
            
            debounceFn(actWord)
        }

        onChange(editorState)
    }
    
    const [editorConfig, setEditorConfig] = useState<any>({
        // The editor theme
        theme: Theme,
        editable: !readOnly,
        editorState: data ? JSON.stringify(data) : null,
        // Handling of errors during update
        onError(error: any) {
          throw error;
        },
        // Any custom nodes go here
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode
        ]
    })


    const _handleEnterCommand = (event: any, editor: any) => {
        if (!handleEnterCommand) return false;
        handleEnterCommand(event, editor)
        editor.update(() => {
            const root = $getRoot();
            root.clear();
            root.append($createParagraphNode());
        });
        return true; 
    }

    const CustomPlugin = () => {
        const [editor] = useLexicalComposerContext();

        useEffect(() => {
            if (editorStateRef) {
                editorStateRef.current = editor;
            }
        }, [editor, editorStateRef]);

        useEffect(() => {
            const removeCommand = editor.registerCommand(
                KEY_ENTER_COMMAND,
                _handleEnterCommand,
                COMMAND_PRIORITY_HIGH
            );

            return () => {
                removeCommand();
            };
        }, [editor]);

        return null;
    };

    const SuggestedAccounts = ({accounts, setAccounts}: any) => {
        const [editor] = useLexicalComposerContext();

        useEffect(() => {
            if (editorStateRef) {
                editorStateRef.current = editor;
            }
        }, [editor, editorStateRef]);

        
    
        const onClickAccount = (acct: any) => {

            
            editor.update(() => {
                const root = $getRoot();
                const textContent = root.getTextContent();
            
                if (!textContent.includes(activeWord)) {
                    console.warn("Active word not found in editor content");
                    return;
                }
            
                // Replace active word with the selected account username followed by a space
                const updatedContent = textContent.replace(activeWord, `@${acct.username} `);
            
                // Clear the root and append updated content wrapped in a ParagraphNode
                root.clear();
                const paragraphNode = $createParagraphNode();
                const textNode = $createTextNode(updatedContent);
                paragraphNode.append(textNode);
                root.append(paragraphNode);
            
                // Place the cursor at the end of the username + space
                const usernameStartIndex = updatedContent.indexOf(`@${acct.username}`);
                const cursorPosition = usernameStartIndex + `@${acct.username} `.length;
            
                textNode.select(cursorPosition, cursorPosition);
            });
    

            setAccounts([])
        }

        if (accounts.length === 0) return <View />
    
        return (
            <View 
                position="absolute" 
                bottom="48px" 
                left="0px" 
                background="#fff" 
                zIndex="2"
                border="solid 1px #D7D7D7"
                width="300px"
            >
                <View display="flex" flexDirection="column" gap="0px">
                    {accounts.map((acct: any) => {
                        return (
                            <View padding="12px 4px" borderBottom="solid 1px #D7D7D7">
                                <ProfilePicture account={acct} photo username fontSize="15px" onClick={() => onClickAccount(acct)}/>
                            </View>
                        )
                    })}
                </View>
            </View>
        )
    }

    return (
        <StyledMarkdownContainer 
            singleLine={singleLine}
            border={border} 
            borderTop={borderTop} 
            borderBottom={borderBottom}
            borderLeft={borderLeft}
            borderRight={borderRight}
            borderRadius={borderRadius}
        >
            <StyledMarkdown singleLine={singleLine}>
                <LexicalComposer initialConfig={editorConfig}>
                    <div className="editor-container">
                        <div className="editor-inner" style={{padding: width <= 768 ? "0" : "0"}}>
                            {noTools && <ToolbarPlugin noTools singleLine allowAttachment={allowAttachment} emoji={emoji}/>}
                            <RichTextPlugin
                                contentEditable={<ContentEditable className="editor-input" id={uid}/>}
                                placeholder={<Placeholder value={placeholder}/>}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <OnChangePlugin onChange={_onChange} />
                            <HistoryPlugin />
                            <AutoFocusPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                            <CustomPlugin />
                            <SuggestedAccounts accounts={suggestedAccounts} setAccounts={setSuggestedAccounts} />
                        </div>
                        {!noTools && <ToolbarPlugin allowAttachment={allowAttachment} noTools={noTools} singleLine={singleLine} emoji={emoji}/>}
                    </div>
                </LexicalComposer>
                
            </StyledMarkdown>
            
        </StyledMarkdownContainer>
      );
}

export default Markdown