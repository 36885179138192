import { ReactNode, forwardRef } from "react";
import { StyledView } from "./styled"
import { useThemeStore } from "../../stores/web.store";

type ViewProps = {
    children?: ReactNode;
    display?: string;
    justifyContent?: string;
    alignItems?: string;
    padding?: string;
    margin?: string;
    width?: string;
    height?: string;
    flexGrow?: string;
    minHeight?: string;
    textAlign?: string;
    background?: string;
    borderRadius?: string;
    flexDirection?: string;
    flex?: string;
    flexWrap?: string;
    gridTemplateColumns?: string;
    gridGap?: string;
    overflow?: string;
    overflowX?: string;
    overflowY?: string;
    border?: string;
    borderBottom?: string;
    borderTop?: string;
    gap?: string;
    color?: string;
    position?: string;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    zIndex?: string;
    transform?: string;
    id?: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
    onChange?: any;
    float?: string;
    maxHeight?: string;
    className?: string;
    style?: any;
    onMouseDown?: any;
    onMouseUp?: any;
    onMouseMove?: any;
    onMouseOut?: any;
    onMouseOver?: any;
}

const View = forwardRef<HTMLDivElement, ViewProps>((props, ref) => {
    const theme = useThemeStore((state: any) => state.theme)
    
    return (
        <StyledView
            {...props}
            ref={ref}
            theme={theme}
            style={props.style}
            onMouseDown={props.onMouseDown}
            onMouseUp={props.onMouseUp}
            onMouseMove={props.onMouseMove}
            onMouseOut={props.onMouseOut}
            onMouseOver={props.onMouseOver}
        >
            {props.children}
        </StyledView>
    )
})

View.displayName = 'View';

export default View