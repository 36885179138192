import { Suspense, useEffect, useState } from 'react'
import { auth } from './services/firebase.service'
import { Routes, Route} from 'react-router-dom'
import { Dashboard } from './pages/Dashboard'
import { Artists } from './pages/Artists'
import { Landing } from './pages/Landing'
import { AdminDashboard } from './pages/AdminDashboard'
import { useAccessStore, useAccountStore, useAdminAccessStore, useLandingPageStateStore, useSuperAdminAccessStore } from './stores/web.store'
import api from './services/api.service'
import { UserDashboard } from './pages/UserDashboard'
import { Explore } from './pages/Explore'
import { PageLoader } from './components/Loader/PageLoader'
import { Artworks } from './pages/Artworks'
import { Auctions } from './pages/Auctions'
import { AuctionPage } from './pages/ProductDetail/Auction'
import { Shows } from './pages/Shows'
import { ShowPage } from './pages/ProductDetail/Show'
import { ArtPatrons } from './pages/ArtPatrons'
import { Galleries } from './pages/Galleries'
import { Museums } from './pages/Museums'
import { GalleryPage } from './pages/ProductDetail/Gallery'
import Artwork from './pages/ProductDetail/Artwork/Artwork'
import { Checkout } from './components/Services/Stripe/Checkout'
import { Complete } from './components/Services/Stripe/Complete'
import { AboutUs } from './pages/Landing/AboutUs'
import { ForArtists } from './pages/Landing/ForArtists'
import { ForCollectors } from './pages/Landing/ForCollectors'
import { ForGalleries } from './pages/Landing/ForGalleries'
import { ForMuseums } from './pages/Landing/ForMuseums'
import { Careers } from './pages/Landing/Careers'
import { Press } from './pages/Landing/Press'
import { ContactUs } from './pages/Landing/ContactUs'
import { Editorials } from './pages/Landing/Editorials'
import { ComingSoon } from './pages/Landing/ComingSoon'
import { Settings } from './pages/Settings'
import { Editorial } from './pages/Landing/Editorial'
import { Map } from './pages/Map'
import { Verify } from './pages/Landing/Verify'
import { People } from './pages/Explore/People'


const Routing = () => {
  const access = useAccessStore((state: any) => state.access)
  const setAccess = useAccessStore((state: any) => state.setAccess)
  const setAccount = useAccountStore((state: any) => state.setAccount)
  const [apiResp, setApiResp] = useState<any>({})

  const setIsSuperAdmin = useSuperAdminAccessStore((state: any) => state.setIsSuperAdmin)
  const isSuperAdmin = useSuperAdminAccessStore((state: any) => state.isSuperAdmin)
  const setIsAdmin = useAdminAccessStore((state: any) => state.setIsAdmin)
  const isAdmin = useAdminAccessStore((state: any) => state.isAdmin)

  useEffect(() => {
    const fetch =  async () => {
      const resp = await api.get('/account')
      setApiResp(resp)
      
      if (resp.status_code === 200) {
        setAccount(resp.data)
        
        if (resp.data?.is_verified) {
          setAccess(true)

        } else {
          setAccess(true)
          // setIsSuperAdmin(false)
        }

        if (resp.data?.role === 'admin') {
          setIsAdmin(true)
        }

        if (resp.data?.email.includes('danielkennedy@primaryyellow.com') || resp.data?.email.includes('jarrenframe@primaryyellow.com') || resp.data?.email.includes('charlesgreen@primaryyellow.com')) {
          setIsSuperAdmin(true)
        }
      } else {
          setAccess(false)
          setIsSuperAdmin(false)
          setIsAdmin(false)
      }
      
    }

    if (!auth.currentUser) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          fetch()
        } else {
          setAccess(false)
          setIsSuperAdmin(false)
          setApiResp({is_loaded: true})
        }
        
      })
    }
    
  }, [auth.currentUser])

  if (!apiResp.is_loaded) return <PageLoader />

  return (
    <Suspense>
        <Routes>
          <Route path="/" element={access ? <Dashboard /> : <Landing />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/explore/people" element={<People />} />
          <Route path="/:username" element={<UserDashboard />} />
          <Route path="/profile" element={<UserDashboard />} />
          <Route path="/profile/:id" element={<UserDashboard />} />
          <Route path="/artworks" element={<Artworks />} />
          <Route path="/artworks/:id" element={<Artwork />} />
          <Route path="/auctions" element={<Auctions />} />
          <Route path="/auctions/:id" element={<AuctionPage />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/shows/:id" element={<ShowPage />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/art-patrons" element={<ArtPatrons />} />
          <Route path="/galleries" element={<Galleries />} />
          <Route path="/galleries/:id" element={<GalleryPage />} />
          <Route path="/museums" element={<Museums />} />
          <Route path="/checkout" element={<Checkout />} />

          
          <Route path="/for-artists" element={<ForArtists />} />
          <Route path="/for-collectors" element={<ForCollectors />} />
          <Route path="/for-galleries" element={<ForGalleries />} />
          <Route path="/for-museums" element={<ForMuseums />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/press" element={<Press />} />
          <Route path="/editorial" element={<Editorials />} />
          <Route path="/editorial/articles/:slug" element={<Editorial />} />

          <Route path="/buy" element={<ComingSoon title={'Buy'} />} />
          <Route path="/bid" element={<ComingSoon title={'Bid'} />} />
          <Route path="/sell" element={<ComingSoon title={'Sell'} />} />
          <Route path="/design-ai" element={<ComingSoon title={'Design AI'} />} />
          {/* <Route path="/invest" element={<ComingSoon title={'Invest'} />} />
          <Route path="/finance" element={<ComingSoon title={'Finance'} />} /> */}

          <Route path="/artmap" element={<Map />} />

          <Route path="/ios" element={<ComingSoon title={'iOS App'} />} />
          <Route path="/verify" element={<Verify />} />
          {/* <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/complete" element={<Complete />} /> */}
          {(isAdmin || isSuperAdmin) && <Route>
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path="/dashboard" element={<AdminDashboard />} />
            {/* <Route path="/artists" element={<Artists />} /> */}
          </Route>}
        </Routes> 
    </Suspense>
  )
}

export default Routing;