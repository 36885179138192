import { View } from "../../View"
import { SearchBar } from "../../SearchBar"
import { StyledShareModalContainer, StyledShareModal, StyledCloseModalContainer, StyledShareContainer } from "./styled"
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { ProfilePicture } from "../../ProfilePicture"
import { Text } from "../../Text"
import { Button } from "../../Button"
import Modal from "../Modal"
import Share from "../../PopOuts/Share/Share"
import { useEffect, useState } from "react"
import api from "../../../services/api.service"


const ShareModal = ({data, setIsModal}: any) => {
    const [filteredData, setFilteredData] = useState<any>([])

    const [accounts, setAccounts] = useState<any>([])

    useEffect(() => {
        const fetchAccounts = async () => {
            const resp = await api.get('/share-accounts')
            setAccounts(resp.data)
            setFilteredData(resp.data)
        }
        fetchAccounts()
    }, [])

    const onClick = (e: any, acct: any) => {
        
    }
    
    return (
        <Modal
            setModal={setIsModal} 
            width={'100%'} 
            height={'100vh'}
            background="rgba(0, 0, 0, .5)"
        >
            <StyledShareModalContainer>
                <StyledShareModal>
                    <View borderBottom="solid 1px grey" padding="0px 0px 12px 0px">
                        <Text width="100%" fontWeight="500" fontSize="20px">{`Share`}</Text>
                    </View>
                    <View padding="12px 0px">
                        <SearchBar 
                            setData={setFilteredData} 
                            data={filteredData} 
                            resetData={accounts}
                            
                        />
                    </View>
                    <View maxHeight="200px" overflowY="auto">
                        <View display="grid" gridTemplateColumns="repeat(4, 1fr)" gridGap="24px">
                            {filteredData.map((acct: any) => {
                                return (
                                    <Button noStyle onClick={(e: any) => onClick(e, acct)} isNoEffect>
                                        <ProfilePicture height="40px" width="40px" account={acct} photo username gap="8px" flexDirection="column" onClick={() => {}} isNoEffect/>
                                    </Button>
                                )
                            })}
                        </View>
                    </View>
                    <StyledShareContainer>
                        <Share post={data}/>
                    </StyledShareContainer>
                </StyledShareModal>
                <StyledCloseModalContainer>
                    <Button noStyle onClick={() => setIsModal(false)}><XIcon strokeWidth={2} height={24} width={24}/></Button>
                </StyledCloseModalContainer>
            </StyledShareModalContainer>              
        </Modal>
    )
}

export default ShareModal