import styled from 'styled-components'


interface DashboardLayoutProps {
    theme: any;
    isModal?: boolean;
}


export const StyledDashboardLayout = styled.div<DashboardLayoutProps>`
    min-height: 100vh;
    width: 100%;
    background: ${props => props.theme.BackgroundColorTheme};
    overflow: hidden;
    font-family: ${props => props.theme.FontFamilyPrimary};
    position: relative;

    ${props => props.isModal && `
        height: 100vh;
    `}
`

interface DashboardContainerProps {
    isModal?: boolean;
    isExpanded?: boolean;
    isCreate?: boolean;
    overflow?: string;
    height?: string;
}


export const StyledDashboardContainer = styled.div<DashboardContainerProps>`
    width: 100%;
    margin: 0;

    @media (min-width: 768px) {
        margin: ${props => props.isCreate ? '0' : '0px 0px 0px 67px'};
        width: ${props => props.isCreate ? '100%' : 'calc(100% - 68px)'};
        height: ${props => props.isCreate ? '100vh' : props.height || 'calc(100vh - 24px)'};
        overflow-x: hidden;
        overflow-y: ${props => props.isModal ? 'hidden' : props.overflow || 'scroll'};
        position: relative;
        padding: ${props => props.isCreate ? '0' : ' 0px'};
        
    }

    @media (min-width: 1024px) {

    }
    
`

interface DashboardProps {
    
}


export const StyledDashboard = styled.div<DashboardProps>`
    
`