import { StyledBack, StyledBackContainer, StyledCreatePost, StyledCreatePostContainer, StyledCreatePostTitleSection, StyledSelectedPostContainer } from "./styled"
import { ReactComponent as ArtworkIcon } from '../../img/artwork.svg';
import { ReactComponent as ShowIcon } from '../../img/exhibitIcon.svg';
import { ReactComponent as MediaIcon } from '../../img/media.svg';
import { ReactComponent as AuctionIcon } from '../../img/auctionIcon.svg';
import { ReactComponent as ChevronLeftIcon } from '../../img/chevronLeft.svg';
import { CreatePostItem } from "./CreatePostItem";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { useState } from "react";
import { useDashboardCreateModeStore, useModalStore, useResponsiveStore, useSelectedPostStore } from "../../stores/web.store";
import { CreateMediaPost } from "./CreateMediaPost";
import { CreateArtworkPost } from "./CreateArtworkPost";
import { CreateShowPost } from "./CreateShowPost";
import { CreateAuctionPost } from "./CreateAuctionPost";
import { useThemeStore } from "../../stores/theme.store";


const CreateMediaItem = () => {
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    const [isHover, setIsHover] = useState(false)

    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        setIsHover(false)
    }

    const onClick = () => {
        setSelectedPost('media')
    }
    return (
        <CreatePostItem 
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isHover={isHover}
            title={'Images & Videos'} 
            color={selectedPost === 'media' ? '#0938F0' : '#000'}
            description={''}
            onClick={onClick}
        >
            <MediaIcon stroke={isHover ? '#0938F0' : selectedPost === 'media' ? '#0938F0' : '#000'} height={80} width={80}/>
        </CreatePostItem>
    )
}

const CreateArtworkItem = () => {
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    const [isHover, setIsHover] = useState(false)

    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        setIsHover(false)
    }

    const onClick = () => {
        setSelectedPost('artwork')
    }
    return (
        <CreatePostItem 
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isHover={isHover}
            title={'Artwork'} 
            color={selectedPost === 'artwork' ? '#0938F0' : '#000'}
            description={''}
            onClick={onClick}
        >
            <ArtworkIcon stroke={isHover ? '#0938F0' : selectedPost === 'artwork' ? '#0938F0' : '#000'} height={80} width={80}/>
        </CreatePostItem>
    )
}

const CreateShowItem = () => {
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    const [isHover, setIsHover] = useState(false)

    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        setIsHover(false)
    }

    const onClick = () => {
        setSelectedPost('show')
    }
    return (
        <CreatePostItem 
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isHover={isHover}
            title={'Show'} 
            color={selectedPost === 'show' ? '#0938F0' : '#000'}
            description={''}
            onClick={onClick}
        >
            <ShowIcon stroke={isHover ? '#0938F0' : selectedPost === 'show' ? '#0938F0' : '#000'} height={80} width={80}/>
        </CreatePostItem>
    )
}

const CreateAuctionItem = () => {
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    const [isHover, setIsHover] = useState(false)

    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        setIsHover(false)
    }

    const onClick = () => {
        setSelectedPost('auction')
    }
    
    return (
        <CreatePostItem
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isHover={isHover}
            title={'Auction'} 
            description={''}
            onClick={onClick}
            color={selectedPost === 'auction' ? '#0938F0' : '#000'}
        >
            <AuctionIcon stroke={isHover ? '#0938F0' : selectedPost === 'auction' ? '#0938F0' : '#000'} height={80} width={80}/>
        </CreatePostItem>
    )
}

const SelectedPost = () => {
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    return (
        <StyledSelectedPostContainer>
            {selectedPost === 'media' && <CreateMediaPost />}
            {selectedPost === 'artwork' && <CreateArtworkPost />}
            {selectedPost === 'show' && <CreateShowPost />}
            {selectedPost === 'auction' && <CreateAuctionPost />}
        </StyledSelectedPostContainer>
    )
}

const CreatePost = () => {
    const theme = useThemeStore((state: any) => state.theme)
    const setSelectedPost = useSelectedPostStore((state: any) => state.setSelectedPost)
    const selectedPost = useSelectedPostStore((state: any) => state.selectedPost)
    const setIsCreateMode = useDashboardCreateModeStore((state: any) => state.setIsCreateMode)
    const width = useResponsiveStore((state: any) => state.width)
    const onClickBack = () => {
        setIsCreateMode(false)
        setSelectedPost(null)
        
    }
    
    return (
        <StyledCreatePostContainer>
            {selectedPost && <StyledBackContainer>
                <StyledBack theme={theme}>
                    <Button noStyle fontWeight="500" fontSize="16px" onClick={onClickBack}><ChevronLeftIcon /> Back</Button>
                </StyledBack>
            </StyledBackContainer>}
            <StyledCreatePostTitleSection>
                <Text fontSize={width > 768 ? "32px" : '20px'} fontWeight="500" textTransform="capitalize" textAlign="left">{selectedPost}</Text>
            </StyledCreatePostTitleSection>
            {/* <StyledCreatePost>
                <CreateMediaItem />
                <CreateArtworkItem />
                <CreateShowItem />
                <CreateAuctionItem />
            </StyledCreatePost> */}
            <SelectedPost />
        </StyledCreatePostContainer>
    )
}

export default CreatePost