import { ReactNode, useState } from "react";
import { useModalStore, usePostStore, useProductDetailPageStore, useProfileStore} from "../../stores/web.store";
import { StyledFollowingContainer, StyledFollowing, StyledHoverInfo, StyledHoverInfoContainer, StyledPhotoContainer, StyledProfilePicture, StyledProfilePictureContainer, StyledProfilePictureWrapper } from "./styled"
import { View } from "../View"
import { Follow } from "../Button/Follow"
import { Image } from "../Image"
import { StyledUserInfo, StyledUserInfoContainer } from "../UserInfo/styled";
import { Text } from "../Text";
import { Button } from "../Button";
import { Username } from "../Button/Username";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyProfileIcon } from "../../img/emptyProfile.svg"
import { toDisplayValue } from "../../utils/app.util";


type ProfilePictureProps = {
    height?: string;
    width?: string;
    textAlign?: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
    noHover?: boolean;
    onClick?: any;
    account?: any;
    username?: boolean;
    role?: boolean;
    photo?: boolean;
    fontSize?: string;
    fontWeight?: string;
    children?: ReactNode
    callback?: any;
    gap?: string;
    isNoEffect?: boolean;
    flexDirection?: string;
}

const HoverInfo = ({data}: any) => {
    return (
        <StyledHoverInfoContainer>
            <StyledHoverInfo>
                <View>
                    <View display="flex" flexDirection="column">
                        <ProfilePicture height="32px" width="32px" textAlign="left" noHover account={data} photo username role/>
                    </View>
                    <View textAlign="center" padding="12px 0px">
                        <StyledFollowingContainer>
                            <StyledFollowing>
                                <Button noStyle>{data.total_followers} Followers</Button>
                                <Button noStyle>{data.total_following} Following</Button>
                            </StyledFollowing>
                        </StyledFollowingContainer>
                    </View>
                </View>
                <View textAlign="center">
                    <Follow data={data}/>
                </View>
            </StyledHoverInfo>
        </StyledHoverInfoContainer>
    )
}

const ProfilePicture = (props: ProfilePictureProps) => {
    const [isHover, setIsHover] = useState(false)

    const navigate = useNavigate()
    const setPdp = useProductDetailPageStore((state: any) => state.setPdp)
    const setProfile = useProfileStore((state: any) => state.setProfile)

    const setPost = usePostStore((state: any) => state.setPost)
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const setIsModal = useModalStore((state: any) => state.setIsModal)

    const onClick = () => {
        navigate(`/${props.account.username || props.account.id}`)
        setProfile(props.account)
        // setPdp('profile')
        setIsModal(false)
        setModalKey(null)

        setPost(null)

        if (props.callback) {
            props.callback()
        }
    }

    let hoverTimeout = null as any;

    const onMouseEnter = () => {
        hoverTimeout = setTimeout(() => {
            setIsHover(true)
        }, 750);
    }

    const onMouseLeave = () => {
        setIsHover(false)
        clearTimeout(hoverTimeout);
    }

    return (
        <StyledProfilePictureWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} flexDirection={props.flexDirection} gap={props.gap}>
            {props.photo && <StyledProfilePictureContainer textAlign={props.textAlign} height={props.height} width={props.width}>
                <Button noStyle onClick={props.onClick || onClick} isNoEffect={props.isNoEffect}>
                    {props.account.profile_picture ? <StyledProfilePicture 
                        src={props.account.profile_picture} 
                        height={props.height || '24px'} 
                        width={props.width || '24px'}
                    /> : <EmptyProfileIcon 
                    height={props.height || '24px'} 
                    width={props.width || '24px'}
                    />}
                </Button>
            </StyledProfilePictureContainer>}
            <View display="flex" flexDirection="column" gap="4px">
                {props.username && <Username 
                    account={props.account} 
                    fontSize={props.fontSize} 
                    fontWeight={props.fontWeight} 
                    onClick={props.onClick || onClick}
                    
                    />
                }
                {props.role && <Text color="#808080" fontSize={"10px"} textTransform="capitalize" textAlign="left">{toDisplayValue(props.account.role)}</Text>}
                {props.children}
            </View>
            
            {isHover && !props.noHover && <HoverInfo data={props.account}/>}
        </StyledProfilePictureWrapper>
    )
}

export default ProfilePicture