import { useState } from "react"
import { View } from "../../../../components/View"
import { Email } from "../../../../components/Input/Email"
import { FirstName } from "../../../../components/Input/FirstName"
import { LastName } from "../../../../components/Input/LastName"
import { Url } from "../../../../components/Input/Url"
import { TextArea } from "../../../../components/TextArea"
import { Button } from "../../../../components/Button"


const Form = () => {
    const [payload, setPayload] = useState<any>({})

    const onSubmit = () => {
        console.log(payload)
    }

    return (
        <View display="flex" flexDirection="column" gap="16px" width="100%">
            <View width="100%">
                <Email payload={payload} setPayload={setPayload} />
            </View>
            <View width="100%">
                <View display="inline-flex" alignItems="center" gap="16px" width="100%">
                    <FirstName payload={payload} setPayload={setPayload} />
                    <LastName payload={payload} setPayload={setPayload} />
                </View>
            </View>
            <View>
                <Url payload={payload} setPayload={setPayload} urlKey="linkedIn" placeholder="LinkedIn URL" />
            </View>
            <View>
                <Url payload={payload} setPayload={setPayload} urlKey="instagram" placeholder="Instagram URL" />
            </View>
            <View>
                <Url payload={payload} setPayload={setPayload} urlKey="x" placeholder="X URL" />
            </View>
            <View>
                <TextArea payload={payload} setPayload={setPayload} textKey="profession" placeholder="What do you do for work?"/> 
            </View>
            <View textAlign="center">
                <Button 
                    padding="12px 32px" 
                    fontSize="20px" 
                    color="#FFF" 
                    onClick={onSubmit}
                >Submit</Button>
            </View>
        </View>
    )
}

export default Form