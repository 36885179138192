import { useEffect, useState } from "react"
import { DiscoverPeoplePost } from "../../components/DiscoverPeoplePost"
import { Text } from "../../components/Text"
import { StyledDiscoverPeople, StyledDiscoverPeopleContainer } from "./styled"
import api from "../../services/api.service"
import { PageLoader } from "../../components/Loader/PageLoader"
import { RippleLoader } from "../../components/Loader/Loaders/RippleLoader"
import { useAccountStore } from "../../stores/web.store"
import { useThemeStore } from "../../stores/theme.store"


type DiscoverPeopleProps = {
    title?: string;
    limit?: number;
}


const DiscoverPeople = ({ title, limit }: DiscoverPeopleProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    const account = useAccountStore((state: any) => state.account)
    const [data, setData] = useState<any>([])
    const [apiResp, setApiResp] = useState<any>([])

    useEffect(() => {
        const fetch = async () => {
            const resp = await api.get(`/discover${limit ? `?limit=${limit}` : ''}`)
            setData(resp.data)
            setApiResp(resp)
        }

        fetch()
    }, [])

    if (!apiResp.is_loaded) return <RippleLoader />

    return (
        <StyledDiscoverPeopleContainer theme={theme}>
            <Text fontSize="24px" fontWeight="600" padding="16px 0px">{title || 'Discover People'}</Text>
            <StyledDiscoverPeople>
                {data.filter((user: any) => user.id !== account.id).map((user: any) => {
                    return (
                        <DiscoverPeoplePost data={user}/>
                    )
                })}
            </StyledDiscoverPeople>
        </StyledDiscoverPeopleContainer>
    )
}

export default DiscoverPeople