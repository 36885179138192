import { useModalStore } from "../../../stores/web.store"
import { Button } from "../../Button"
import { Text } from "../../Text"
import { View } from "../../View"
import { StyledEditProfile, StyledEditProfileContainer } from "./styled"
import {ReactComponent as XIcon} from '../../../img/x.svg'
import { Input } from "../../Input"
import { Select } from "../../Select"
import { Switch } from "../../Switch"
import { ProfilePicture } from "../../ProfilePicture"
import { Address } from "../../Address"
import { useState } from "react"
import { Modal } from "../../Modal"
import { toFormattedAddress } from "../../../utils/app.util"
import api from "../../../services/api.service"
import { ChangePhoto } from "../../Button/ChangePhoto"

const Username = ({payload, setPayload}: any) => {
    const [exists, setExists] = useState<any>(null)
    const onChange = (e: any) => {
        setPayload({...payload, username: e.target.value})
    }

    const onBlur = async () => {
        const resp = await api.get(`/accounts/${payload.username}/exists?key=username`)
        if (resp.exists) {
            setPayload({...payload, username: null})
        }

        setExists(resp.exists ? payload.username : null)
        
    }

    return (
        <View display="flex" flexDirection="column" gap="8px">
            <Text fontWeight="600" fontSize="16px">Username</Text>
            {exists && <Text color="red">{`${exists} already exists`}</Text>}
            <Input 
                width="100%" 
                border="solid 1px #C4C4C4" 
                value={payload.username} 
                onChange={onChange}
                onBlur={onBlur}
            />
        </View>
    )
}

const Website = ({payload, setPayload}: any) => {
    const onChange = (e: any) => {
        setPayload({...payload, links: {...(payload.links || {}), website: e.target.value}})
    }

    return (
        <View display="flex" flexDirection="column" gap="8px">
            <Text fontWeight="600" fontSize="16px">Website</Text>
            <Input width="100%" border="solid 1px #C4C4C4" onChange={onChange} value={payload.links?.website}/>
        </View>
    )
}

const Profession = ({payload, setPayload}: any) => {
    const onChange = (e: any) => {
        setPayload({...payload, profession: e.target.value})
    }
    return (
        <View display="flex" flexDirection="column" gap="8px">
            <Text fontWeight="600" fontSize="16px">Profession</Text>
            <Input width="100%" border="solid 1px #C4C4C4" onChange={onChange} value={payload.profession}/>
        </View>
    )
}

const PrimaryLocation = ({payload, setIsModal}: any) => {
    return (
        <View display="flex" flexDirection="column" gap="8px">
            <Text fontWeight="600" fontSize="16px">Primary Location</Text>
            {payload.address?.street_address && <View>
                <Text>{toFormattedAddress(payload.address)}</Text>   
            </View>}
            <Button
                border="solid 1px #BFCBFB"
                background="#DDE3FD"
                color="#0838F0"
                padding="12px"
                width="100%"
                borderRadius="20px"
                fontWeight="600"
                fontSize="16px"
                onClick={() => setIsModal(true)}
            >{payload.address?.street_address ? 'Update Address' : 'Add Address'}</Button>
        </View>
    )
}

const Bio = ({payload, setPayload}: any) => {
    const onChange = (e: any) => {
        setPayload({...payload, bio: e.target.value})
    }
    return (
        <View display="flex" flexDirection="column" gap="8px">
            <Text fontWeight="600" fontSize="16px">Bio</Text>
            <Input width="100%" border="solid 1px #C4C4C4" onChange={onChange} value={payload.bio}/>
        </View>
    )
}

const AccountSuggestions = ({payload, setPayload}: any) => {
    return (
        <View display="flex" flexDirection="column" gap="8px">
            <Text fontWeight="600" fontSize="16px">Show account suggestions on profiles</Text>
            <View 
                display="inline-flex" 
                alignItems="center" 
                border="solid 1px #C4C4C4" 
                borderRadius="10px"
                padding="20px"
            >
                <View width="70%">
                    <Text textAlign="left">{"Choose whether people can see similar account suggestions on your profile, and whether your account can be suggested on other profiles."}</Text>
                </View>
                <View width="30%" textAlign="right">
                    <Switch />
                </View>

            </View>
            
        </View>
    )
}

const SaveButton = ({payload, setPayload, setIsModal}: any) => {

    const onClick = async () => {
        if (payload.address?.street_address) {
            payload.address.is_primary = true
        }
        
        await api.put('/profile', payload)
        setIsModal(false)
    }

    return (
        <Button
            border="solid 1px #BFCBFB"
            background="#DDE3FD"
            color="#0838F0"
            padding="12px"
            width="100%"
            borderRadius="20px"
            fontWeight="600"
            fontSize="16px"
            onClick={onClick}
        >Save changes</Button>
    )
}

const ChangePhotoSection = ({profile}: any) => {
    const onClick = async () => {
        
    }
    return (
        <View background="#EDEDED" padding="12px 24px" borderRadius="10px">
            <View display="inline-flex" alignItems="center" width="100%">
                <View width="50%" textAlign="left">
                    <ProfilePicture account={profile} username photo height="54px" width="54px" onClick={onClick} noHover/>
                </View>
                <View width="50%" textAlign="right">
                    <ChangePhoto />
                </View>
            </View>
        </View>
    )
}


const AddressModal = ({payload, setPayload, setIsModal}: any) => {
    return (
        <View background="#fff" padding="24px" borderRadius="10px">
            <View>
                <Text fontSize="18px">Enter Address</Text>
            </View>
            <Address setPayload={setPayload} payload={payload} setIsModal={setIsModal}/>
        </View>
    )
}


const EditProfile = ({profile, setIsParentModal}: any) => {
    const [isModal, setIsModal] = useState(false)
    const [payload, setPayload] = useState<any>({
        username: profile.username,
        links: profile.profile?.links,
        address: (profile.addresses || []).find((address: any) => address.is_primary),
        bio: profile.profile?.bio,
        profession: profile.profile?.profession
    })
    return (
        <StyledEditProfileContainer>
            <StyledEditProfile>
                <View>
                    <View display="flex" flexDirection="column" gap="24px">
                        <View>
                            <ChangePhotoSection profile={profile} />
                        </View>
                        
                        <View textAlign="left">
                            <Username payload={payload} setPayload={setPayload}/>
                        </View>
                        <View textAlign="left">
                            <Website payload={payload} setPayload={setPayload}/>
                        </View>
                        <View textAlign="left">
                            <Profession payload={payload} setPayload={setPayload}/>
                        </View>
                        <View textAlign="left">
                            <PrimaryLocation payload={payload} setIsModal={setIsModal}/>
                        </View>
                        <View textAlign="left">
                            <Bio payload={payload} setPayload={setPayload}/>
                        </View>
                        <View textAlign="left">
                            <AccountSuggestions />
                        </View>
                        <View>
                            <SaveButton payload={payload} setPayload={setPayload} setIsModal={setIsParentModal}/>
                        </View>
                    </View>
                </View>
            </StyledEditProfile>
            {isModal && <Modal setModal={setIsModal}>
                <AddressModal payload={payload} setPayload={setPayload} setIsModal={setIsModal} />
            </Modal>}
        </StyledEditProfileContainer>
    )
}

export default EditProfile