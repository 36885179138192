import { UploadingPost } from "../../components/UploadingPost"
import { useActiveFeedStore, usePostStore, useUploadStore } from "../../stores/web.store"
import { Following } from "../Following"
import { ForYou } from "../ForYou"
import { StyledFeed, StyledFeedContainer } from "./styled"


const Feed = () => {
    const post = usePostStore((state: any) => state.post)
    const isUploading = useUploadStore((state: any) => state.isUploading)
    const activeFeed = useActiveFeedStore((state: any) => state.activeFeed)
    return (
        <StyledFeedContainer>
            {isUploading && post && <UploadingPost />}
            <StyledFeed>
                {activeFeed === 'following' && <Following />}
                {activeFeed === 'forYou' && <ForYou />}
            </StyledFeed>
        </StyledFeedContainer>
    )
}

export default Feed