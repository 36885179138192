import { useEffect, useRef } from "react";
import { format } from 'date-fns';
import { CURRENCY_SYMBOL_MAP } from "../map.app";

export const useComponentVisible = (setData: any) => {
    const ref = useRef<any>(null);

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setData(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref };
}

export const boolean2Value = (bool: boolean, trueValue?: any, falseValue?: any) => {
    if (bool) {
        return trueValue || 'included'
    } else {
        return falseValue || 'not included'
    }
    
}

const KEY_MAP: Record<string, string> = {
    has_coa: 'Certificate of Authenticity',
}

export const key2Value = (key: any, trueValue?: any, falseValue?: any) => {
    if (typeof key === 'string' || key instanceof String) {
        const keyStr = key.toString();  
        if (KEY_MAP[keyStr]) return KEY_MAP[keyStr]
        return keyStr.replace(/_/g, ' ')
    }
    if (typeof key == "boolean") {
        return boolean2Value(key, trueValue, falseValue)
    }
    return key
}

export const formatValue = (value: any, trueValue?: any, falseValue?: any) => {
    if (typeof value === 'string' || value instanceof String) {
        return value.replace(/_/g, ' ')
    }
    if (typeof value == "boolean") {
        return boolean2Value(value, trueValue, falseValue)
    }
    try {
        return toCurrency(parseFloat(value as string))
    } catch {
        return value
    }
    
    
}

export const toPhoneNumber = (value?: string) => {
    return value?.replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export const toFormattedNumber = (value: number) => {
    if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value
    
}

export const toCurrency = (value: number, currency?: string) => {
    return `${CURRENCY_SYMBOL_MAP[currency || 'usd']}${toFormattedNumber(value || 0)}`
}

export const toDate = (value: string, yearOnly?: boolean) => {
    const date = new Date(value);
    const month = date.toLocaleString('default', { month: 'long' });

    if (yearOnly) {
        return `${date.getFullYear()}`
    }
    return `${month} ${date.getDate()}, ${date.getFullYear()}`
}

export const toDateRange = (startDate: string, endDate: string): string => {
    try {
        const start = new Date(startDate);
        const end = new Date(endDate);
    
        const startDay = format(start, 'dd');
        const startMonth = format(start, 'MMM');
        const endDay = format(end, 'dd');
        const endMonth = format(end, 'MMM');
        const year = format(end, 'yyyy');
    
        return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${year}`;
    } catch {
        return ''
    }
  };

export const toUTC = (date: Date) => {
    const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(now_utc).toISOString()
                
}

const _countdown = (days: number, hours: number, minutes: number, seconds: number, countdownKeys='dhms') => {
    
    const store: any = {
        'd': ' days ago',
        'h': ' hours ago',
        'm': ' minutes ago',
        's': ' seconds ago'
    }

    const _value = (value: number, key: string, includeComma: boolean = false) => {
        if (countdownKeys.includes(key)) {
            return `${includeComma ? ', ' : ''}${value}${store[key]}`
        }
        return ''
    }

    if (days >= 1) return `
        ${_value(days, 'd')}${_value(hours, 'h', true)}${_value(minutes, 'm', true)}${_value(seconds, 's', true)}
    `

    if (hours >= 1) return `
        ${_value(hours, 'h')}${_value(minutes, 'm', true)}${_value(seconds, 's', true)}
    `

    if (minutes >= 1) return `
        ${_value(minutes, 'm')}${_value(seconds, 's', true)}
    `
    return `${_value(seconds, 's')}`
}

export const toDateDiff = (value: string, isCountDown = false, countdownKeys='dhms') => {

    // Get the current time in UTC
    const now = new Date(Date.now());

    // Create a date object from the value string, ensuring it's treated as UTC
    const date = new Date(value + 'Z'); // Append 'Z' to treat it as UTC

    let milliseconds = date.getTime() - now.getTime();
    if (now.getTime() > date.getTime()) {
        milliseconds = now.getTime() - date.getTime();
    }

    const seconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(milliseconds / 86400000); // days
    const hours = Math.floor((milliseconds % 86400000) / 3600000); // hours
    const minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000); // minutes

    if (isCountDown) {
        return _countdown(days, hours, minutes, seconds, countdownKeys);
    }

    if (days >= 1) return `${days}${days > 1 ? 'ds' : 'd'} `;
    if (hours >= 1) return `${hours}${hours > 1 ? 'hs' : 'h'}`;
    if (minutes >= 1) return `${minutes}${minutes > 1 ? 'ms' : 'm'}`;

    return `${seconds}${seconds > 1 ? 's' : 's'}`;
};

const _toStateByDate = (data: any, setTimeValue: any, setState: any, stateInterval: any) => {
    const now = new Date()
    const start_datetime = new Date(data.metadata.start_datetime)
    const end_datetime = new Date(data.metadata.end_datetime)
    if (now >= end_datetime) {
        setTimeValue(toDateDiff(data.metadata.start_datetime, true))
        setState('ended')
        clearInterval(stateInterval);
        
    }
    
    if (now < start_datetime) {
        setTimeValue(toDateDiff(data.metadata.start_datetime, true))
        setState('upcoming')
    }

    if (now >= start_datetime && now < end_datetime) {
        setTimeValue(toDateDiff(data.metadata.end_datetime, true))
        setState('active')
    }
}

export const toAuctionState = (data: any, setTimeValue: any, setAuctionState: any) => {
    const auctionStateInterval = setInterval(function () {
        _toStateByDate(data, setTimeValue, setAuctionState, auctionStateInterval)
    }, 1000);
    _toStateByDate(data, setTimeValue, setAuctionState, auctionStateInterval)
}

export const toStateByDate = (data: any, setTimeValue: any, setState: any) => {
    const stateInterval = setInterval(function () {
        _toStateByDate(data, setTimeValue, setState, stateInterval)
    }, 1000);
    _toStateByDate(data, setTimeValue, setState, stateInterval)
}

export const lexicalFocus = (uid: string) => {
    
    (document.getElementById(uid) as HTMLInputElement).focus();
}

export const lexicalClear = (uid: string) => {
    const elem = (document.getElementById(uid) as HTMLInputElement);

}

export const toOption = (options: any, value: any) => {
    return options.filter((opt: any) => opt.value === value)
}

export const toDBValue = (value: string) => {
    return value.toLowerCase().replace(/ /g, '_')
}

const _street_address_two = (address: any) => {
    if (address.street_address_two) {
        return `, ${address.street_address_two}`
    }
    return ''
}

export const toFormattedAddress = (address: any) => {
    return `${address.street_address}${_street_address_two(address)}, ${address.city}, ${address.state} ${address.zipcode}`
}

export const toDisplayValue = (value: any) => {
    const displayValue = value.replace(/_/g, ' ')
    return displayValue
}

export const toUsername = (user: any) => {
    if (user.username) return user.username
    if (user.profile?.display_name) return user.profile?.display_name
    return `${user.first_name} ${user.last_name}`
}

export const useEffectOnce = (effect: () => void) => {
    const effectRan = useRef(false);

    useEffect(() => {
        if (effectRan.current) return;
        effect();
        effectRan.current = true;
    }, [effect]);
};
