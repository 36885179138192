import { View } from "../../../components/View"
import { DashboardLayout } from "../../../sections/Layout/DashboardLayout"
import { FooterAd } from "../../../sections/Layout/Footer/FooterAd"
import { StyledHeroContainer } from "./styled"
import { useResponsiveStore } from "../../../stores/web.store"
import { Form } from "./Form"
import { Text } from "../../../components/Text"




const Verify = () => {
    const width = useResponsiveStore((state: any) => state.width)
    return (
        <DashboardLayout>
            <StyledHeroContainer>
                <View display="flex" alignItems="center" height="405px">
                    {/* <Text padding="0px 0px 0px 64px" fontSize="4vw" color="#fff" fontWeight="600">The Future of Art Collecting</Text> */}
                </View>
            </StyledHeroContainer>
            <View padding={width > 768 ? "48px" : "12px"} display="flex" flexDirection="column" gap="48px">
                <View width="600px" margin="auto">
                    <View display="flex" flexDirection="column" gap="32px">
                        <View textAlign="center">
                            <Text width="100%" fontSize={width > 768 ? "32px" : "28px"} fontWeight="500">{"Verify with Primary Yellow"}</Text>
                        </View>
                        <Form />
                    </View>
                </View>
                <View>
                    <FooterAd image={'/img/landing/about/LaunchingSoon.svg'} />
                </View>
            </View>
        </DashboardLayout>
    )
}

export default Verify