import styled from 'styled-components'
import { StringLiteral } from 'typescript';


interface ButtonProps {
    theme: any;
    noStyle?: boolean;
    noEffect?: boolean;
    background?: string;
    border?: string;
    borderBottom?: string;
    borderRadius?: string;
    padding?: string;
    fontSize?: string;
    fontStyle?: string;
    fontWeight?: string;
    display?: string;
    color?: string
    cursor?: string;
    width?: string;
    minWidth?: string;
    height?: string;
    lineHeight?: string;
    disbaled?: boolean
    hoverEffect?: any;
    textAlign?: string;
    justifyContent?: string;
    alignItems?: string;
    textDecoration?: string;
    textTransform?: string;
    isPressed?: boolean;
    isVideo?: boolean;
    isNoEffect?: boolean;
    isActive?: boolean;
    float?: string;
}


export const StyledButton = styled.button<ButtonProps>`
    cursor: pointer;
    font-family: ${props => props.theme.global.fontFamily};
    background: ${props => props.background || (props.noStyle ? 'unset' : props.theme.button.background)};
    border: ${props => props.border || (props.noStyle ? 'unset' : props.theme.button.border)};
    text-transform: ${props => props.textTransform || 'none'};
    text-decoration: ${props => props.textDecoration || 'none'};
    border-radius: ${props => props.borderRadius || (props.noStyle ? 'unset' : props.theme.button.borderRadius)};
    color: ${props => props.color || props.theme.button.color};
    padding: ${props => props.padding || (props.noStyle ? '0' : props.theme.button.padding)};
    font-size: ${props => props.fontSize || props.theme.button.fontSize};
    font-weight: ${props => props.fontWeight || props.theme.button.fontWeight};
    line-height: ${props => props.lineHeight || props.theme.button.lineHeight};
    width: ${props => props.width || props.theme.button.width};
    height: ${props => props.height || props.theme.button.height};
    text-align: ${props => props.textAlign || props.theme.button.textAlign};
    display: ${props => props.display || props.theme.button.display};
    font-style: ${props => props.fontStyle || 'normal'};
    

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: ${props => (props.isVideo || props.isNoEffect || props.disabled) ? '1' : '.5'};
    }

    &:active:hover {
        opacity: .6;
        background: ${props => props.isNoEffect ? 'none' : '#DDE3FD'};
        // color: #0938F0;
        // border: solid 1px #BFCBFB;
    }

    ${props => props.disabled  && `
        color:rgba(0, 0, 0, .35);
        cursor: unset;
    `};

    ${props => props.minWidth && `
        min-width: ${props.minWidth};
    `}

    ${props => props.float && `
        float: ${props.float};
    `}

    ${props => props.noStyle && props.border && !props.isActive &&`
        &:hover {
            background: #FFFFFF;
            color: #0938F0;
            // border: solid 1px #0938F0;
        }
        
    `}

    ${props => props.isPressed && props.noStyle &&`
    
    `}

    ${props => props.isPressed && `
        opacity: .7;
    `}

    ${props => props.cursor && `
        cursor: ${props.cursor};
    `}

    ${props => props.borderBottom && `
        border-bottom: ${props.borderBottom};
    `}

    ${props => props.disabled && !props.noStyle && `
        background: rgba(207, 207, 207, 1);
        cursor: not-allowed;
        border: rgba(207, 207, 207, 1);
        color: #000;
    `};

    ${props => !props.disabled && props.hoverEffect && `
        &:hover {
            background: ${props.hoverEffect.background || 'none'};
            color: ${props.hoverEffect.color || props.color || props.theme.button.color};
            border: 1px solid rgba(255, 255, 255, 0);
        }
    `};
`

interface ButtonContentContainerProps {
    theme: any;
    height?: string;
}


export const StyledButtonContentContainer = styled.div<ButtonContentContainerProps>`
    
    height: ${props => props.height || props.theme.button.height};
    width: 100%;
`

interface ButtonContentProps {
    theme: any;
    height?: string;
    justifyContent?: string;
}


export const StyledButtonContent = styled.div<ButtonContentProps>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent || 'center'};
    width: 100%;
    height: ${props => props.height || props.theme.button.height};
`

interface ButtonContentWrapperProps {
    display?: string;
    justifyContent?: string;
    gap?: string;
    width?: string;
    flexDirection?: string;
    alignItems?: string;
}



export const StyledButtonContentWrapper = styled.div<ButtonContentWrapperProps>`
    display: ${props => props.display || 'flex'};
    align-items: ${props => props.alignItems || 'center'};
    flex-direction: ${props => props.flexDirection || 'inline-flex'};
    justify-content: ${props => props.justifyContent || 'center'};
    width: ${props => props.width || '100%'};
    height: auto;
    gap: ${props => props.gap || '4px'};


`