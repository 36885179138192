import { useEffect, useRef, useState } from "react"
import RangeSlider from "react-range-slider-input";
import Image from "../Image/Image"
import { StyledBackButton, StyledBackNavigator, StyledGalleryImage, StyledImageGallery, StyledImageGalleryContainer, StyledNextButton, StyledNextNavigator, StyledZoomSlider, StyledZoomSliderContainer } from "./styled"
import { Button } from "../Button"
import { Video } from "../Video"
import { useModalStore, useProductDetailPageStore } from "../../stores/web.store"
import { View } from "../View"

import {ReactComponent as LeftIcon} from '../../img/leftNavigator.svg'
import {ReactComponent as RightIcon} from '../../img/rightNavigator.svg'



const ZoomSlider = ({setZoomLevel, zoomLevel}: any) => {
    const [value, setValue] = useState([0, 1]);

    const onClickIncrease = () => {
        setZoomLevel(zoomLevel + .1)
    }

    const onClickDecrease = () => {
        setZoomLevel(Math.max(1, zoomLevel - .1))
    }

    const onSlide = async (e: any) => {
        if (e[1] > value[1]) {
            setZoomLevel(zoomLevel + .1)
            setValue([0, value[1] + .1])
        } else {
            const newZoom = Math.max(1, zoomLevel - .1)
            setZoomLevel(newZoom)
            setValue([0, Math.max(1, value[1] - .1)])
        }
    }
    return (
        <StyledZoomSliderContainer>
            <StyledZoomSlider>
                <StyledZoomSlider>
                    <RangeSlider min={1} max={10} step={.1} value={value} onInput={onSlide} thumbsDisabled={[true, false]}/>
                </StyledZoomSlider>
            </StyledZoomSlider>
        </StyledZoomSliderContainer>
    )
}

const BackNavigator = ({data, idx, setActiveIndex, isHover}: any) => {

    const onClick = () => {
        if (idx === 0) {
            setActiveIndex((data.media || data.posts).length - 1)
        } else {
            setActiveIndex(idx - 1)
        }
    }

    if (idx === 0) return <div />

    return (
        <StyledBackNavigator isHover={isHover}>
            <Button noStyle onClick={onClick}>
                <LeftIcon height={24} width={24}/>
            </Button>
        </StyledBackNavigator>
    )
}

const NextNavigator = ({data, idx, setActiveIndex, isHover}: any) => {

    const onClick = () => {
        if (idx === ((data.media || data.posts).length - 1)) {
            setActiveIndex(0)
        } else {
            setActiveIndex(idx + 1)
        }
    }

    if (idx === ((data.media || data.posts).length - 1)) return <div />

    return (
        <StyledNextNavigator isHover={isHover}>
            <Button noStyle onClick={onClick}>
                <RightIcon height={24} width={24}/>
            </Button>
        </StyledNextNavigator>
    )
}

type GalleryImageProps = {
    data?: any;
    image?: any;
    idx: number;
    setActiveIndex?: any;
    width?: string;
    height?: string;
    objectFit?: string;
    setPost?: any;
    isModal?: boolean;
    isThumbnail?: boolean;
    onClick?: any;
    borderRadius?: string;
    setModalDimensions?: any;
    fullscreen?: boolean
}


const GalleryImage = ({data, setModalDimensions, image, idx, setActiveIndex, width, height, objectFit, setPost, isModal, isThumbnail, borderRadius, onClick, fullscreen}: GalleryImageProps) => {
    const setModalKey = useModalStore((state: any) => state.setModalKey)
    const modalKey = useModalStore((state: any) => state.modalKey)

    const [isHover, setIsHover] = useState(false)
    const [zoomLevel, setZoomLevel] = useState(1)
    const [panPosition, setPanPosition] = useState({ x: 0, y: 0 })
    const [isDragging, setIsDragging] = useState(false)
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
    const imageRef = useRef<HTMLImageElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const handleMouseDown = (e: React.MouseEvent) => {
        if (zoomLevel > 1) {
            setIsDragging(true)
            setDragStart({
                x: e.clientX - panPosition.x,
                y: e.clientY - panPosition.y
            })
            e.preventDefault()
        }
    }

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging && zoomLevel > 1) {
            const newX = e.clientX - dragStart.x
            const newY = e.clientY - dragStart.y
            setPanPosition({ x: newX, y: newY })
            e.preventDefault()
        }
    }

    const handleMouseUp = () => {
        setIsDragging(false)
    }

    useEffect(() => {
        // Reset pan position when zoom level changes
        setPanPosition({ x: 0, y: 0 })
    }, [zoomLevel])

    useEffect(() => {
        const handleResize = () => adjustModalDimensions();
        window.addEventListener('resize', handleResize);
        adjustModalDimensions();

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, [imageRef]);

    const adjustModalDimensions = () => {
        if (!imageRef.current) return;
        
        const image = imageRef.current;
        const { naturalWidth, naturalHeight } = image;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Calculate aspect ratios
        const imageAspectRatio = naturalWidth / naturalHeight;
        const windowAspectRatio = windowWidth / windowHeight;

        let modalWidth = naturalWidth;
        let modalHeight = naturalHeight;

        // Adjust dimensions to fit within window without white space
        if (imageAspectRatio > windowAspectRatio) {
            // Image is wider relative to its height compared to the window
            modalWidth = Math.min(naturalWidth, windowWidth * 0.90);
            modalHeight = modalWidth / imageAspectRatio;
        } else {
            // Image is taller relative to its width compared to the window
            modalHeight = Math.min(naturalHeight, windowHeight * 0.90);
            modalWidth = modalHeight * imageAspectRatio;
        }

        // Check if we need to use object-fit: cover
        const shouldCover = modalWidth >= windowWidth;

        if (setModalDimensions) {
            setModalDimensions({
                modalWidth,
                ratio: modalWidth / modalHeight,
                modalHeight,
                objectFit: shouldCover ? 'cover' : 'contain'
            });
        }

        
    };

    const onClickPost = () => {
        if (data.type === 'artwork') {
            setModalKey('media')
        } else {
            setModalKey('media')
        }
        if (setPost)
            setPost(data)  
    }

    return (
        <StyledGalleryImage height={'auto'} borderRadius={borderRadius}>
            <View onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                {(isModal || fullscreen) && data.media?.length > 1 && <NextNavigator data={data} idx={idx} setActiveIndex={setActiveIndex} isHover={isHover}/>}
                {(isModal || fullscreen) && data.media?.length > 1 && <BackNavigator data={data} idx={idx} setActiveIndex={setActiveIndex} isHover={isHover}/>}
                <Button 
                    noStyle 
                    onClick={onClick || onClickPost} 
                    width="100%" 
                    display="block" 
                    height="inherit" 
                    isVideo={(image.content_type || image.type || image.file.type).includes('video')}
                    isNoEffect

                >
                    {(image.content_type || image.type || image.file.type).includes('video') ? 
                        <View>
                            <Video src={image.url} width={fullscreen ? '100%' : width || "100%"} height={fullscreen ? '100%' : (zoomLevel > 1 ? '100%' : height || "250px")} objectFit={fullscreen ? 'contain' : (objectFit || 'cover')} zoom={zoomLevel}/>
                        </View> : 
                        <View
                            ref={containerRef}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseUp}
                            style={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                cursor: isDragging ? 'grabbing' : (zoomLevel > 1 ? 'grab' : fullscreen ? 'default' : 'pointer'),
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Image 
                                    imageRef={imageRef} 
                                    src={image.url} 
                                    width={fullscreen ? '100%' : width || "100%"} 
                                    height={fullscreen ? '100%' : (zoomLevel > 1 ? '100%' : height || "250px")} 
                                    objectFit={fullscreen ? 'contain' : (objectFit || 'cover')}
                                    isNoEffect={isModal}
                                    style={{
                                        transform: zoomLevel > 1 ? `scale(${zoomLevel}) translate(${panPosition.x / zoomLevel}px, ${panPosition.y / zoomLevel}px)` : undefined,
                                        transition: isDragging ? 'none' : 'transform 0.1s ease-out',
                                        pointerEvents: isDragging ? 'none' : 'auto',
                                        transformOrigin: 'center center',
                                        maxWidth: '100%',
                                        maxHeight: '100%'
                                    }}
                                />
                            </div>
                        </View>
                    }
                    
                </Button>
                {(isModal && modalKey === 'media' || fullscreen) && <ZoomSlider setZoomLevel={setZoomLevel} zoomLevel={zoomLevel}/>}
            </View>
        </StyledGalleryImage>
    )
}

const Dot = ({idx, setActiveIndex, activeIndex}: any) => {
    const onClick = () => {
        if (activeIndex !== idx) {
            setActiveIndex(idx)
        }
    }
    return (
        <Button noStyle onClick={onClick}>
            <View height="8px" width="8px" background={idx === activeIndex ? '#fff' : 'grey'} borderRadius="8px"/>
        </Button>
    )
}

const ImageGallery = ({data, setModalDimensions, setPost, height, objectFit, width, isModal, isThumbnail, borderRadius, onClick, fullscreen}: any) => {
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <StyledImageGalleryContainer>
            <StyledImageGallery>
                {((data.media && data.media.length > 0 ? data.media : data.posts || [])).map((media: any, idx: number) => {
                    const image = media.media ? media.media[0] : media
                    return (
                        <div>
                            {activeIndex === idx && <GalleryImage 
                                setModalDimensions={setModalDimensions}
                                data={data} 
                                key={`gallery-image-${idx}`} 
                                image={image} 
                                height={height}
                                width={width}
                                objectFit={objectFit}
                                idx={idx} 
                                setActiveIndex={setActiveIndex} 
                                setPost={setPost}
                                isModal={isModal}
                                isThumbnail={isThumbnail}
                                onClick={onClick}
                                borderRadius={borderRadius}
                                fullscreen={fullscreen}
                            />}
                        </div>
                    )
                })}
                {isModal && !isThumbnail && ((data.media && data.media.length > 0 ? data.media : data.posts || [])).length > 1 && <View position="absolute" bottom="4px" width="100%" textAlign="center">
                    <View display="inline-flex" alignItems="center" justifyContent="center" gap="8px">
                    {((data.media && data.media.length > 0 ? data.media : data.posts || [])).slice(0, 5).map((media: any, idx: number) => {
                        return (
                            <Dot idx={idx} setActiveIndex={setActiveIndex} activeIndex={activeIndex}/>
                        )
                    })}
                    </View>
                </View>}
                
            </StyledImageGallery>
            
        </StyledImageGalleryContainer>
    )
}

export default ImageGallery