import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createTextNode, $getSelection, $isRangeSelection, createCommand } from "lexical";
import { useEffect, useState } from "react";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Button } from "../../Button";
import {ReactComponent as EmojiIcon} from '../../../img/emoji.svg'
import {ReactComponent as CloseIcon} from '../../../img/close.svg'
import { StyledEmoji, StyledEmojiContainer } from "../styled";



const EmojiPlugin = ({singleLine, emoji}: any) => {
    const ADD_EMOJI_COMMAND = createCommand();
    const [isVisible, setIsVisible] = useState(false)

    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerCommand(
            ADD_EMOJI_COMMAND,
            (payload: any) => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    selection.insertNodes([$createTextNode(payload)]);
                }
                return true;
            },
            1
        );
    }, [editor, isVisible]);

    const onClick = () => {
        setIsVisible(!isVisible)
    }

    const onEmojiSelect = (d: any) => {
        editor.dispatchCommand(ADD_EMOJI_COMMAND, d.native);
        setIsVisible(false)
    }

    return (
        <StyledEmojiContainer singleLine={singleLine} emoji={emoji}>
            <Button float="left" onClick={onClick} noStyle>{isVisible ? <CloseIcon height={18} width={18}/> : <EmojiIcon height={18} width={18}/>}</Button>
            <StyledEmoji isActive={isVisible} singleLine={singleLine}>
                <Picker
                    data={data}
                    theme='light'
                    onEmojiSelect={(d: any) => onEmojiSelect(d)}
                />
            </StyledEmoji>
        </StyledEmojiContainer>
    );
}

export default EmojiPlugin
