/*
https://console.cloud.google.com/google/maps-apis/studio/styles?inv=1&invt=AbkkCg&project=primary-yellow
*/

import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import styled from 'styled-components';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useSwipeable } from 'react-swipeable';
import { toDate } from '../../utils/app.util';
import {ReactComponent as SearchIcon} from '../../img/search.svg'
import { View } from '../View';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Input } from '../Input';
import { Text } from '../Text';
import api from '../../services/api.service';
import { PageLoader } from '../Loader/PageLoader';


export interface Show {
  id: string;
  title: string;
  location: any;
  dates: string;
  hours: string;
  name: string;
  metadata: any;
}

interface MapProps {
  shows: readonly Show[];
  googleMapsApiKey: string;
}

const MapContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background: #FFFDF4;
  position: relative;
`;

const ShowsPanel = styled.div`
  width: 400px;
  height: 100%;
  background: white;
  overflow-y: auto;
  border-right: 1px solid #E5E0C6;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileShowCard = styled.div<{ active: boolean }>`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(${props => props.active ? '0' : '100%'});
  transition: transform 0.3s ease-out;
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* For iOS momentum scrolling */

  /* Handle various mobile device types */
  @media (max-width: 768px), 
         (max-device-width: 768px),
         (hover: none) and (pointer: coarse) {
    display: block;
  }

  /* Ensure content is visible on notched devices */
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
  }

  /* Prevent text selection during swipe */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const SwipeHandle = styled.div`
  width: 40px;
  height: 4px;
  background: #E5E0C6;
  border-radius: 2px;
  margin: 0 auto 15px;
  touch-action: none; /* Prevent scrolling while touching the handle */
`;

const ShowsList = styled.div`
  padding: 20px;
`;

const ShowCard = styled.div<{ active: boolean }>`
  padding: 20px;
  margin-bottom: 20px;
  background: ${props => props.active ? '#1A1A1A' : 'white'};
  color: ${props => props.active ? '#FFF8D6' : '#1A1A1A'};
  border: 1px solid #E5E0C6;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  h3 {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 500;
  }

  p {
    margin: 4px 0;
    font-size: 14px;
    color: ${props => props.active ? '#E5E0C6' : '#666'};
  }
`;

const MapWrapper = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFDF4;
  z-index: 1;
`;

const SearchModal = ({setIsModal, setVisibleShows, map}: any) => {
  const [searchType , setSearchType] = useState('city');
  const [searchQuery, setSearchQuery] = useState('');

  const onClickSearch = async () => {
    const resp = await api.post(`/search/artmap`, {query: {
      [`location.${searchType}`]: searchQuery
    }})

    if (resp.data && resp.data.length > 0) {
      // setVisibleShows(resp.data); #TODO Uncomment when we implement pan and zoom search functionality
      if (map && resp.data[0].location) {
        map.panTo(resp.data[0].location);
        map.setZoom(13);
      }
      setIsModal(false);
    }
  }

  const onChangeInput = (e: any) => {
    setSearchQuery(e.target.value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClickSearch();
    }
  }

  return (
    <Modal setModal={setIsModal} height="max-content" width="448px">
      <View>
        <View display="flex" gap="12px" alignItems="center" flexDirection="column" padding="24px 0px">
          <View>{searchType === 'zipcode' ? 'Zipcode' : 'City'}</View>
          <Input 
            placeholder={searchType === 'zipcode' ? 'Zipcode' : 'City'} 
            noPlaceholderPrefix 
            width="400px"
            onChange={onChangeInput}
            onKeyDown={onKeyDown}
          />
          <Button padding="8px 12px" color="#FFF" onClick={onClickSearch}>Search</Button>
          <Button 
            noStyle 
            fontStyle='italic' 
            fontSize='11px' 
            color="#0938F0" 
            fontWeight='500'
            onClick={() => setSearchType(searchType === 'zipcode' ? 'city' : 'zipcode')}
          >{`Search by ${searchType === 'zipcode' ? 'City' : 'Zipcode'}`}</Button>
        </View>
      </View>
    </Modal>
  )
}

const SwipeableShowCard: React.FC<{
  show: Show;
  active: boolean;
  onDismiss: () => void;
}> = ({ show, active, onDismiss }) => {
  const swipeHandlers = useSwipeable({
    onSwipedDown: onDismiss,
    trackMouse: false,
    trackTouch: true,
    delta: 50,
    preventScrollOnSwipe: true,
    swipeDuration: 500,
    touchEventOptions: { passive: true },
  });

  return (
    <MobileShowCard 
      active={active} 
      {...swipeHandlers}
      role="dialog"
      aria-modal="true"
      aria-label={`Details for ${show.title}`}
    >
      <SwipeHandle role="presentation" />
      <h3>{show.title}</h3>
      <p>{show.name}</p>
      <p>{show.hours}</p>
      <p>{show.location.street_address}</p>
      <p>{toDate(show.metadata?.start_date)} to {toDate(show.metadata?.end_date)}</p>
    </MobileShowCard>
  );
};

const Map = ({ shows, googleMapsApiKey }: MapProps) => {
  const [activeShow, setActiveShow] = useState<string | null>(null);
  const [visibleShows, setVisibleShows] = useState<Show[]>([]);
  const showsPanelRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markerClustererRef = useRef<MarkerClusterer | null>(null);
  const markersRef = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
  const [hoveredMarkerId, setHoveredMarkerId] = useState<string | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number>(13);
  const [isMobileShowCardVisible, setIsMobileShowCardVisible] = useState(false);

  const [isModal, setIsModal] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries: ['marker', 'geometry']
  });

  const mapOptions = useMemo(() => ({
    mapId: '35d7ef40e8f83208',
    disableDefaultUI: true,
    zoomControl: true,
    scrollwheel: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoom: 13,
    minZoom: 3,
    maxZoom: 18
  }), []);

  const defaultCenter = useMemo(() => ({ 
    lat: 40.7831,
    lng: -73.9712
  }), []);

  const scrollToShow = useCallback((showId: string) => {
    const element = document.getElementById(`show-${showId}`);
    if (element && showsPanelRef.current) {
      showsPanelRef.current.scrollTo({
        top: element.offsetTop - 100,
        behavior: 'smooth'
      });
    }
  }, []);

  const handleShowClick = useCallback((showId: string) => {
    const isDeactivating = activeShow === showId;
    
    if (!isDeactivating && map) {
      const show = shows.find(s => s.id === showId);
      if (show) {
        const currentZoom = map.getZoom() || 13;
        if (currentZoom < 16) {
          map.setZoom(16);
        }
        map.panTo(show.location);
      }
    }
    
    setActiveShow(isDeactivating ? null : showId);
    setIsMobileShowCardVisible(isDeactivating ? false : true);
  }, [map, shows, activeShow]);

  const handleMarkerClick = useCallback((showId: string) => {
    setActiveShow(prevId => prevId === showId ? null : showId);
    setIsMobileShowCardVisible(activeShow !== showId);
  }, [activeShow]);

  const shouldShowLabel = useCallback((showId: string) => {
    return (currentZoom || 13) >= 16 || hoveredMarkerId === showId || activeShow === showId;
  }, [currentZoom, hoveredMarkerId, activeShow]);

  const createMarkerElement = useCallback((show: Show) => {
    const markerElement = document.createElement('div');
    markerElement.style.position = 'relative';
    markerElement.innerHTML = `
      <div style="position: relative;">
        <svg width="16" height="16" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <circle fill="${activeShow === show.id ? '#FFDC39' : '#1A1A1A'}" cx="16" cy="16" r="16"/>
          <circle fill="${activeShow === show.id ? '#1A1A1A' : '#FFDC39'}" cx="16" cy="16" r="8"/>
        </svg>
        <div style="
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 20px;
          background: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          display: ${shouldShowLabel(show.id) ? 'block' : 'none'};
          pointer-events: none;
          z-index: 1;
        ">
          ${show.title}
        </div>
      </div>
    `;

    markerElement.addEventListener('mouseenter', () => {
      setHoveredMarkerId(show.id);
    });

    markerElement.addEventListener('mouseleave', () => {
      setHoveredMarkerId(null);
    });

    return markerElement;
  }, [activeShow, shouldShowLabel]);

  // Create markers when map is loaded
  const handleMapLoad = useCallback((map: google.maps.Map) => {
    if (!window.google) return;

    // Clear existing markers
    markersRef.current.forEach(marker => {
      marker.map = null;
    });
    markersRef.current = [];

    if (markerClustererRef.current) {
      markerClustererRef.current.clearMarkers();
    }

    // Create markers
    const newMarkers = shows.map(show => {
      const markerElement = createMarkerElement(show);
      markerElement.style.cursor = 'pointer';
      
      const marker = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: show.location,
        content: markerElement,
      });

      marker.addListener('click', () => {
        handleMarkerClick(show.id);
      });

      return marker;
    });

    markersRef.current = newMarkers;

    // Initialize MarkerClusterer
    const clusterRenderer = (cluster: { count: number, position: google.maps.LatLng | google.maps.LatLngLiteral }) => {
      const count = cluster.count;
      const position = cluster.position;

      const clusterElement = document.createElement('div');
      clusterElement.innerHTML = `
        <div style="
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          background: #1A1A1A;
          border-radius: 50%;
          color: #FFDC39;
          font-size: 12px;
          font-weight: 500;
        ">
          ${count}
        </div>
      `;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: clusterElement,
      });

      return marker;
    };

    markerClustererRef.current = new MarkerClusterer({
      map,
      markers: newMarkers,
      renderer: {
        render: clusterRenderer
      }
    });

    // Fit bounds to show all markers
    const bounds = new google.maps.LatLngBounds();
    shows.forEach(show => {
      bounds.extend({ lat: show.location.lat, lng: show.location.lng });
    });
    map.fitBounds(bounds);
    setMap(map);
  }, [shows, activeShow, createMarkerElement, handleMarkerClick]);

  // Handle zoom changes
  useEffect(() => {
    if (!map) return;
    
    const listener = map.addListener('zoom_changed', () => {
      setCurrentZoom(map.getZoom() || 13);
    });

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [map]);

  // Update visible shows when map bounds change
  const updateVisibleShows = useCallback(() => {
    if (!map) return;

    const bounds = map.getBounds();
    if (!bounds) return;

    const newVisibleShows = shows.filter(show => 
      bounds.contains({ lat: show.location.lat, lng: show.location.lng })
    );

    setVisibleShows(newVisibleShows);
  }, [map, shows]);

  // Handle map bounds changes
  useEffect(() => {
    if (!map) return;
    
    const listener = map.addListener('bounds_changed', () => {
      updateVisibleShows();
    });

    // Initial update
    updateVisibleShows();

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [map, updateVisibleShows]);

  // Update markers when zoom, hover, or active state changes
  useEffect(() => {
    if (!window.google) return;

    markersRef.current.forEach((marker, index) => {
      if (marker.content instanceof HTMLElement) {
        const show = shows[index];
        marker.content.innerHTML = `
          <div style="position: relative;">
            <svg width="16" height="16" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <circle fill="${activeShow === show.id ? '#FFDC39' : '#1A1A1A'}" cx="16" cy="16" r="16"/>
              <circle fill="${activeShow === show.id ? '#1A1A1A' : '#FFDC39'}" cx="16" cy="16" r="8"/>
            </svg>
            <div style="
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 20px;
              background: ${activeShow === show.id ? '#1A1A1A' : '#FFF'};
              color: ${activeShow === show.id ? '#FFF' : '#1A1A1A'};
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;
              white-space: nowrap;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
              display: ${shouldShowLabel(show.id) ? 'block' : 'none'};
              pointer-events: none;
              z-index: 1;
            ">
              ${show.title}
            </div>
          </div>
        `;
      }
    });
  }, [shows, activeShow, currentZoom, hoveredMarkerId, shouldShowLabel]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      markersRef.current.forEach(marker => {
        marker.map = null;
      });
      if (markerClustererRef.current) {
        markerClustererRef.current.clearMarkers();
      }
    };
  }, []);

  const renderShowCard = useCallback((show: Show) => (
    <ShowCard
      key={show.id}
      id={`show-${show.id}`}
      active={activeShow === show.id}
      onClick={() => handleShowClick(show.id)}
    >
      <h3>{show.title}</h3>
      <p>{show.name}</p>
      <p>{show.hours}</p>
      <p>{show.location.street_address}</p>
      <p>{toDate(show.metadata?.start_date)} to {toDate(show.metadata?.end_date)}</p>
    </ShowCard>
  ), [activeShow, handleShowClick]);

  useEffect(() => {
    if (activeShow) {
      scrollToShow(activeShow);
    }
  }, [activeShow, scrollToShow]);

  const handleDismissShowCard = useCallback(() => {
    setActiveShow(null);
    setIsMobileShowCardVisible(false);
  }, []);

  const mobileShowCard = useMemo(() => {
    if (!activeShow) return null;
    
    const show = shows.find(s => s.id === activeShow);
    if (!show) return null;

    return (
      <SwipeableShowCard
        show={show}
        active={isMobileShowCardVisible}
        onDismiss={handleDismissShowCard}
      />
    );
  }, [activeShow, shows, isMobileShowCardVisible, handleDismissShowCard]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <MapContainer>
        <ShowsPanel>
          {shows.map(renderShowCard)}
        </ShowsPanel>
        <MapWrapper>
          <PageLoader />
        </MapWrapper>
      </MapContainer>
    );
  }

  return (
    <MapContainer>
      <ShowsPanel ref={showsPanelRef}>
        <ShowsList>
          {visibleShows.map(renderShowCard)}
        </ShowsList>
      </ShowsPanel>
      <MapWrapper>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={shows.length ? { lat: shows[0].location.latitude, lng: shows[0].location.longitude } : defaultCenter}
          options={mapOptions}
          zoom={13}
          onLoad={handleMapLoad}
        />
      </MapWrapper>
      {mobileShowCard}
      <View position="fixed" right="12px" top="12px">
          <Button 
            noStyle 
            onClick={() => setIsModal(true)}
            background="#FFDC39" 
            borderRadius="50%"
            padding="8px"
          ><SearchIcon width={20} height={20} strokeWidth={4}/></Button>
      </View>
      {isModal && <SearchModal setIsModal={setIsModal} setVisibleShows={setVisibleShows} map={map}/>}
    </MapContainer>
  );
};

export default Map;
